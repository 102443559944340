/**
 * @ngdoc factory
 * @name commonModule.AuditTrailService
 * @description This is a factory used for audit and trail related REST calls
 * @requires $http for POST request
 * @requires $resource for other REST calls.
 */
(function() {
  'use strict';

  angular.module('commonModule')
          .factory(
                  'EmailTemplateService',
                  [
                      '$http',
                      '$resource',
                      '$log',
                      function($http, $resource, $log) {
                        return {
                          getGMEmailTemplateParameters: function(templateCode) {
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                              },
                              url: 'v2/gm/agent/email/templates/' + templateCode + '/parameters',
                              method: 'GET'
                            });
                          },
                        };
                      }]);
})();
