/**
 * @ngdoc factory
 * @name dwtModule.searchShareholderService
 * @description service for search  shareholder functionlaty
 * @requires $http for POST request
 * @requires $resource for other REST calls.
 */
(function () {
  'use strict';
  angular.module('dwtModule').factory('searchShareholderService', [
    '$http',
    function ($http) {
      return {
        searchShareholder: function (data, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/search/shareholder',
            method: 'POST',
            data: data,
          });
        },
        getIntermediaryList: function () {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/peshareholders/DWT',
            method: 'GET',
          });
        },
        getIssuerList: function (productCode, userId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/issuers/' + productCode + '/' + userId + '/dropdowns',
            method: 'GET',
          });
        },
        getShareholderClaimDetails: function (
          shareholderId,
          userType,
          userId,
          productCode,
          userGroupId
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/search/shareholder/' +
              shareholderId +
              '/' +
              userType +
              '/' +
              userId +
              '/' +
              productCode +
              '/' +
              userGroupId +
              '/claims',
            method: 'GET',
          });
        },
      };
    },
  ]);
})();
