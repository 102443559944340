import {
  MenuItem,
  HOME_LABELS,
  ACCOUNT_LABELS,
  GM_LABELS,
  DWT_LABELS,
  SHID_LABELS,
} from './_common';

export function getIntermediaryMenuItems(lang, userRoleProductCodes) {
  const labels = {
    home: HOME_LABELS,
    account: ACCOUNT_LABELS,
    gm: GM_LABELS,
    tax: DWT_LABELS,
    shid: SHID_LABELS,
  };

  const menus = [
    new MenuItem(labels.home[lang], 'actionHome', 0),
    new MenuItem(labels.account[lang], 'myAccounts', 4),
  ];

  if (userRoleProductCodes.includes('GM')) {
    menus.push(new MenuItem(labels.gm[lang], 'intermediaryGeneralMeeting', 1));
  }

  if (userRoleProductCodes.includes('DWT')) {
    menus.push(new MenuItem(labels.tax[lang], 'taxReclaimHome', 2));
  }

  if (userRoleProductCodes.includes('SHID')) {
    menus.push(new MenuItem(labels.shid[lang], 'shareholders-id', 3));
  }

  return menus;
}
