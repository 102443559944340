
(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name : dateFormatterService
   * @description : Service function for formatting date
   */
  angular.module('commonModule').factory('dateFormatterService', [
    '$filter',
    function ($filter) {
      return {
        dateToStringConverter : function (dateObj) {
          if (dateObj !== null) {
            var date = new Date(dateObj);
            return $filter('date')(date, 'yyyy-MM-dd HH:mm:ss');
          }
          return null;
        }
      };
    },
  ]);
})();

