(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name : view dwt detail service
   * @returns : rest calls to view dwt detail, creating JSON request objects for
   *          service calls.
   * @description : Service to view dwt detail.
   */
  angular.module('dwtModule').factory('ViewDwtDetailService', [
    '$http',
    '$sessionStorage',
    '$resource',
    function ($http, $sessionStorage, $resource) {
      return {
        getPaymentDetailsOverview: function (eventId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/dwtpayments/' + eventId + '/paymentDetails',
            method: 'GET',
          });
        },
        paymentCompletedAction: function (commentsData) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/dwtevents/' +
              commentsData.eventId +
              '/dwtclaims/payment/disburse',
            method: 'POST',
            data: commentsData,
          });
        },
        paymentReceivedAction: function (commentsData) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/dwtevents/' +
              commentsData.eventId +
              '/dwtclaims/payment/confirm',
            method: 'POST',
            data: commentsData,
          });
        },
        getDWTClaimDetailsOverview: function (
          groupId,
          eventId,
          userType,
          pageNo
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/taxreclaim/' +
              groupId +
              '/' +
              eventId +
              '/' +
              userType +
              '/' +
              pageNo +
              '/500/claims',
            method: 'GET',
          });
        },
        getDwtDetailsOverview: function (eventId, userType, languageCode) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/taxreclaim/' +
              $sessionStorage.groupId +
              '/' +
              eventId +
              '/' +
              userType +
              '/' +
              languageCode +
              '/events',
            method: 'GET',
          });
        },
        updateEventComments: function (commentsData, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/taxreclaim/comments',
            method: 'POST',
            data: commentsData,
          });
        },
        submitClaims: function (claimsIdList, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/taxreclaim/submit',
            method: 'POST',
            data: claimsIdList,
          });
        },
        toBeAuthorizeSubmitClaims: function (
          claimsToSubmit,
          eventId,
          authtoken
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/dwtevents/' + eventId + '/dwtclaims/authorize',
            method: 'POST',
            data: claimsToSubmit,
          });
        },
        undoSubmitOrReject: function (undoData, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/taxreclaim/undo',
            method: 'POST',
            data: undoData,
          });
        },
        getDwtClaimsDetailsView: function (claimId, userType, eventId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/taxreclaim/' +
              $sessionStorage.groupId +
              '/' +
              claimId +
              '/' +
              eventId +
              '/' +
              userType +
              '/view',
            method: 'GET',
          });
        },
        getDwtToBeAuthorizedData: function (
          eventId,
          userType,
          userGroupId,
          pageNo
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/dwtevents/' +
              eventId +
              '/dwtclaims/authorize/' +
              userType +
              '/' +
              userGroupId +
              '/' +
              pageNo +
              '/500',
            method: 'GET',
          });
        },
        getDwtGetEmailData: function (eventId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/dwtevents/' + eventId + '/sendtotaxauthority/data ',
            method: 'GET',
          });
        },
        sendDWTEmail: function (eventId, userId) {
          var reqData = {
            loggedInUserId: userId,
          };
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/dwtevents/' + eventId + '/sendtotaxauthority',
            method: 'POST',
            data: reqData,
          });
        },
        rejectClaim: function (rejectData, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/taxreclaim/reject',
            method: 'POST',
            data: rejectData,
          });
        },
        deleteClaim: function (eventId, claimId, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/dwtevents/' + eventId + '/dwtclaims/' + claimId,
            method: 'DELETE',
          });
        },
        deleteClaimForm: function (formId, formType, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/claimforms/' + formId + '/' + formType + '/delete',
            method: 'DELETE',
          });
        },
        getExtensionRequestStatus: function (intermediaryId, eventId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/extensionrequest/' +
              intermediaryId +
              '/' +
              eventId +
              '/status',
            method: 'GET',
          });
        },
        requestQrfPayment: function (eventId, paymentData, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/dwtevents/' + eventId + '/dwtclaims/payment/request/qrf',
            method: 'POST',
            data: paymentData,
          });
        },
        viewClaimForms: function (userId, formId, formType) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              responseType: 'arraybuffer',
            },
            url:
              'v1/claimforms/' +
              userId +
              '/' +
              formId +
              '/' +
              formType +
              '/view',
            method: 'GET',
          });
        },
        getCurrentCETTime: function () {
          return $resource(
            'v1/shareholderusers/timestamp',
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getShareholderList: function (
          issuerId,
          prodCode,
          langCode,
          userId,
          eventId
        ) {
          return $resource(
            'v1/peshareholders/issuer/' +
              issuerId +
              '/' +
              prodCode +
              '/' +
              userId +
              '/' +
              eventId +
              '/event',
            {},
            {
              query: {
                method: 'GET',
              },
            }
          );
        },
      };
    },
  ]);
})();
