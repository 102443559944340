/**
 * @ngdoc factory
 * @name commonModule.generalMeetingService
 * @description This is a factory used for general meeting related REST calls
 * @requires $http for POST request
 * @requires $resource for the REST calls
 * @requires $sessionStorage to store/pass session data
 * @requires $log to handle debug logs
 */
import { userTypeToPathSegment } from '@/lib/workstationPathSegment';

(function () {
  'use strict';

  angular.module('commonModule').factory('generalMeetingService', [
    '$http',
    '$sessionStorage',
    '$resource',
    '$log',
    function ($http, $sessionStorage, $resource, $log) {
      return {
        getGeneralMeetingsHome: function () {
          return $resource(
            'v1/portalusers/' +
              $sessionStorage.usertype +
              '/' +
              $sessionStorage.userId +
              '/1/500/generelMeetings',
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getGeneralMeetingsStatusCount: function (searchParam) {
          return $resource(
            '/v1/portalusers/generalMeetings/statusCount?filter=' + searchParam,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getGeneralMeetings: function (
          pageNumber,
          pageSize,
          event,
          sortDirection,
          sortBy,
          searchParam
        ) {
          return $resource(
            'v1/portalusers/' +
              'generalMeetings?pageNumber=' +
              pageNumber +
              '&pageSize=' +
              pageSize +
              '&status=' +
              event +
              '&sortDirection=' +
              sortDirection +
              '&sortBy=' +
              sortBy +
              '&filter=' +
              searchParam,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getGeneralMeetingsAction: function () {
          return $resource(
            'v1/portalusers/' +
              $sessionStorage.usertype +
              '/' +
              $sessionStorage.userId +
              '/' +
              $sessionStorage.groupId +
              '/1/500/generelMeetings/actions',
            {},
            {
              get: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getDwtMeetingsAction: function (pageNumber, size) {
          return $resource(
            'v1/dwtevents/list/' +
              $sessionStorage.usertype +
              '/' +
              pageNumber +
              '/' +
              size +
              '/actions',
            {},
            {
              get: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },

        cancelInstruction: function (data) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: '/v1/instructions/cancel',
            method: 'PUT',
            data: data,
          });
        },

        updateInstruction: function (data) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: '/v1/instructions/update',
            method: 'PUT',
            data: data,
          });
        },

        updateShareplanHolderPosition: function (data) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: '/v1/reinvite/update',
            method: 'PUT',
            data: data,
          });
        },

        getGMAttendance: function (shareClassId, meetingId) {
          return $resource(
            'v1/shareclasses/' +
              $sessionStorage.usertype +
              '/' +
              shareClassId +
              '/' +
              meetingId +
              '/' +
              $sessionStorage.gmLang +
              '/registrations/attendance',
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },

        getRegisteredVirtualParticipations: function (
          scId,
          gmId,
          page,
          search,
          userType
        ) {
          let url = `v2/gm/${userTypeToPathSegment(
            userType
          )}/general-meetings/${gmId}/shareclasses/${scId}/virtual-participations?status=APPROVED&pageNumber=${page}&sortBy=REGISTRATION_NUMBER`;

          if (search != null) {
            url += `&filter=${search}`;
          }

          return $resource(
            url,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },

        //SFCBP-2312 - Below is the service call to fetch agenda and corresponding voting results of individual instruction
        getVotingResult: function (votingInstructionId, languageCode) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/votingresults/' +
              votingInstructionId +
              '/' +
              languageCode +
              '/viewVoting',
            method: 'GET',
          });
        },
        //End - SFCBP-2312
        searchAttendanceRegistration: function (
          shareClassId,
          meetingId,
          searchData,
          authtoken
        ) {
          $log.debug();
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url:
              'v1/shareclasses/' +
              $sessionStorage.usertype +
              '/' +
              shareClassId +
              '/' +
              meetingId +
              '/' +
              $sessionStorage.gmLang +
              '/registrations/attendance/' +
              searchData,
            method: 'GET',
          });
        },
        searchVotingInstructionRegistration: function(shareClassId,
        meetingId,
        pageNumber,
        pageSize,
        participationMethods,
        searchData,
        authtoken
        ) {
        $log.debug();
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url:
                'v1/general-meetings/' +
                meetingId +
                '/' +
                'shareclasses' +
                '/' +
                shareClassId +
                '/' +
                'voting-instruction-registrations' +
                '?participationMethods=' +
                participationMethods +
                '&pageNumber=' +
                pageNumber +
                '&pageSize=' +
                pageSize +
                '&searchString=' +
                searchData,
            method: 'GET',
          });
        },
        getGmDetails: function (gmId, shareClassId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/reinvite/' +
              shareClassId +
              '/' +
              gmId +
              '/' +
              $sessionStorage.gmLang +
              '/onLoadResendInviteSharePlan',
            method: 'GET',
          });
        },
        getGMSharePlanHolders: function (
          shareClassId,
          meetingId,
          pageNumber,
          pageSize,
          searchData,
          authtoken
        ) {
          $log.debug();
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url:
              'v1/reinvite/' +
              shareClassId +
              '/' +
              meetingId +
              '/' +
              $sessionStorage.gmLang +
              '/reInviteSharePlanAndSearch/' +
              pageNumber +
              '/' +
              pageSize +
              '?search=' +
              searchData,
            method: 'GET',
          });
        },

        gmDeleteAgendaService: function (
          meetingID,
          agendaID,
          authtoken,
          callback
        ) {
          let response = $resource(
            'v1/generalmeetings/' + meetingID + '/agendas/' + agendaID,
            {},
            {
              save: {
                method: 'DELETE',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'X-Internal-Auth-Token': authtoken,
                },
              },
            }
          );
          let _resource = new response();
          _resource.$save(callback);
        },

        gmDeleteDocumentService: function (
          meetingID,
          documentID,
          authtoken,
          callback
        ) {
          let response = $resource(
            'v1/generalmeetings/' + meetingID + '/documents/' + documentID,
            {},
            {
              save: {
                method: 'DELETE',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'X-Internal-Auth-Token': authtoken,
                },
              },
            }
          );
          let _resource = new response();
          _resource.$save(callback);
        },

        gmDeleteShareClassService: function (
          meetingID,
          enrollID,
          authtoken,
          callback
        ) {
          let response = $resource(
            'v1/generalmeetings/' + meetingID + '/shareclasses/' + enrollID,
            {},
            {
              save: {
                method: 'DELETE',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'X-Internal-Auth-Token': authtoken,
                },
              },
            }
          );
          let _resource = new response();
          _resource.$save(callback);
        },

        sendToIssuerApprove: function (meetingID, data, authtoken) {
          $log.debug(data);
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/generalmeetingdetails/status/' + meetingID,
            method: 'PUT',
            data: data,
          });
        },
        validateOnlySendToIssuerApprove: function (meetingID) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/generalmeetings/' + meetingID + '/validate',
            method: 'GET',
          });
        },
        getCreateGMDropDown: function () {
          return $resource(
            'v1/generalmeetingdetails/data',
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
              update: {
                method: 'PUT',
              },
            }
          );
        },
        getCountryListByLanguage: function (languageCode) {
          return $resource(
            '/v2/common/open/countries',
            {
              languageCode: languageCode,
            },
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getCountryListMultilanguage: function () {
          return $resource(
            'v1/shareholderusers/generelMeetings/countrylist',
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
              update: {
                method: 'PUT',
              },
            }
          );
        },
        getCreateGMVoteCollectors: function (agentId, issuerId) {
          return $resource(
            'v1/users/agent/' + agentId + '/issuer/' + issuerId,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
              update: {
                method: 'PUT',
              },
            }
          );
        },
        getEuroclearEmailTemplate: function (gmId, langCode) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/generalmeeting/issuerreports/euroclear/' +
              gmId +
              '/' +
              langCode +
              '/' +
              $sessionStorage.userId,
            method: 'GET',
          });
        },
        persistVcDisplayName: function (data, authtoken) {
          $log.debug(data);
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/generalmeetings/persistDisplayName',
            method: 'POST',
            data: data,
          });
        },
        viewVcDisplayName: function (groupId, userType, meetingId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/generalmeetings/' +
              groupId +
              '/' +
              userType +
              '/' +
              meetingId +
              '/viewDisplayName',
            method: 'GET',
          });
        },
        getCountOfVI: function (gmId, shareClassId, landingThrough) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/shareclasses/' +
              landingThrough +
              '/' +
              $sessionStorage.usertype +
              '/' +
              gmId +
              '/votecollectors/' +
              shareClassId,
            method: 'GET',
          });
        },
        sendMailEuroclear: function (data, authtoken) {
          $log.debug(data);
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/generalmeeting/issuerreports/euroclear/sendReportInEmail',
            method: 'POST',
            data: data,
          });
        },
        getGeneralMeetingShareClasses: function (meetingId) {
          return $resource(
            'v2/gm/general-meetings/' + meetingId + '/meeting-shareclasses',
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getAssignedGeneralMeetingShareClasses: function (meetingId) {
          return $resource(
              'v2/gm/general-meetings/' + meetingId + '/assigned-meeting-shareclasses',
              {},
              {
                query: {
                  method: 'GET',
                  isArray: false,
                },
              }
          );
        },
        getGeneralMeetingView: function (meetingId) {
          if ($sessionStorage.userId) {
            return $resource(
              'v1/generalmeetingdetails/viewgeneralmeeting/' +
                meetingId +
                '/' +
                $sessionStorage.usertype,
              {},
              {
                query: {
                  method: 'GET',
                  isArray: false,
                },
              }
            );
          } else {
            return $resource(
              'v1/shareholderusers/generelMeetings/' + meetingId,
              {},
              {
                query: {
                  method: 'GET',
                  isArray: false,
                },
              }
            );
          }
        },



        deleteGeneralMeeting: function (
          meetingId,
          status,
          authtoken,
          callback
        ) {
          let response = $resource(
            'v1/generalmeetingdetails/' + meetingId + '/' + status + '/cancel',
            {},
            {
              save: {
                method: 'PUT',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'X-Internal-Auth-Token': authtoken,
                },
              },
            }
          );
          let _resource = new response();
          _resource.$save(callback);
        },

        getNotificationInfo: function (meetingId, langCode, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url:
              'v1/generalmeetingdetails/' +
              meetingId +
              '/' +
              langCode +
              '/notification',
            method: 'GET',
          });
        },

        updateNotificationCustomisedMessageInDB: function (
          meetingId,
          data,
          authtoken
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/generalmeetingdetails/' + meetingId + '/notification',
            method: 'PUT',
            data: data,
          });
        },

        updateNotificationCustomisedMessageInSheet: function (
          meetingId,
          data,
          authtoken
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/generalmeetingdetails/' + meetingId + '/notification',
            method: 'PUT',
            data: data,
          });
        },

        // [ALM-10700]- start
        getCurrentCETTime: function () {
          return $resource(
            'v1/shareholderusers/timestamp',
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        }, // [ALM-10700] end
        sendNotificationGeneralMeeting: function (meetingId, data, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/generalmeetingdetails/' + meetingId + '/notification',
            method: 'POST',
            data: data,
          });
        },
        getLiveMeetingStatistics: function (pageNumber, pageSize, issuerName) {
          issuerName = issuerName === undefined ? '' : issuerName;
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              '/v1/agent/livemeeting/stats?' +
              'filter=' +
              issuerName +
              '&pageNumber=' +
              pageNumber +
              '&pageSize=' +
              pageSize,
            method: 'GET',
          });
        },
      };
    },
  ]);
})();
