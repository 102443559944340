import {
  MenuItem,
  HOME_LABELS,
  ACCOUNT_LABELS,
  GM_LABELS,
  DWT_LABELS,
} from './_common';

export function getIssuerMenuItems(
  lang,
  userRoleProductCodes,
  { userGroupProductCodes }
) {
  const labels = {
    home: HOME_LABELS,
    gm: GM_LABELS,
    tax: DWT_LABELS,
    account: ACCOUNT_LABELS,
  };
  const menus = [
    new MenuItem(labels.home[lang], 'issuerActionHome', 0),
    new MenuItem('Shareholders ID', 'shareholders-id', 3),
    new MenuItem(labels.account[lang], 'myAccounts', 4),
  ];

  if (userRoleProductCodes.includes('GM')) {
    menus.push(new MenuItem(labels.gm[lang], 'generalMeetingHome', 1));
  }

  if (userRoleProductCodes.includes('DWT')) {
    menus.push(new MenuItem(labels.tax[lang], 'taxReclaimHome', 2));
  }

  return menus;
}
