(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name : save GM service
   * @returns : rest calls to save/ update GM for all the sections, creating JSON request objects for service calls.
   * @description : Service to generate JSON request objects for service calls.
   */
  angular.module('commonModule').factory('SaveGmService', [
    '$http',
    '$log',
    function ($http, $log,) {
      return {
        // Service function for create general meeting type and location
        createGmTypeAndLocation: function (data, validateTl) {
          $log.debug(data);
          var url = '';
          if (validateTl) {
            url =
              'v1/generalmeetings/' +
              data.generalMeetingId +
              '/typeandlocation/validate';
          } else {
            url = 'v1/generalmeetings';
          }
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: url,
            method: 'POST',
            data: data,
          });
        },
        // Service function for create general meeting schedule
        createGmScheduleTab: function (meetingId, data, validateSc) {
          $log.debug(data);
          var url = '';
          if (validateSc) {
            url = 'v1/generalmeetings/' + meetingId + '/schedule/validate';
          } else {
            url = 'v1/generalmeetings/' + meetingId + '/schedule';
          }
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: url,
            method: 'POST',
            data: data,
          });
        },

        // Service function for create general meeting share class
        createGmShareClasses: function (meetingId, data, validateSh) {
          var url = '';
          if (validateSh) {
            url = 'v1/generalmeetings/' + meetingId + '/shareclasses/validate';
          } else {
            url = 'v1/general-meetings/' + meetingId + '/meeting-shareclasses';
          }
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: url,
            method: 'POST',
            data: data,
          });
        },
        // Service function for create general meeting agenda
        createGmAgenda: function (meetingId, data, validateAg) {
          $log.debug(data);
          var url = '';
          if (validateAg) {
            url = 'v1/generalmeetings/' + meetingId + '/agendas/validate';
          } else {
            url = 'v1/generalmeetings/' + meetingId + '/agendas';
          }
          return $http.post(url, data, {
            transformRequest: angular.identity,
            headers: {
              'Content-Type': undefined,
            },
          });
        },
        // Service function for create general meeting feature
        createGmFeature: function (meetingId, data) {
          $log.debug(data);
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/generalmeetings/' + meetingId + '/features',
            method: 'POST',
            data: data,
          });
        },
        // Service function for create general meeting documents
        createGmDocuments: function (meetingId, data, validateDoc) {
          $log.debug(data);
          var url = '';
          if (validateDoc) {
            url = 'v1/generalmeetings/' + meetingId + '/documents/validate';
          } else {
            url = 'v1/generalmeetings/' + meetingId + '/documents';
          }
          return $http.post(url, data, {
            transformRequest: angular.identity,
            headers: {
              'Content-Type': undefined,
            },
          });
        },

      };
    },
  ]);
})();
