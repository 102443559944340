/**
 * @ngdoc service
 * @name dwtModule.DwtProgressService
 * @description This is a service used for progress bar in general meeting view
 * @requires $sessionStorage to store/pass session data
 * @requires $filter
 */
angular.module('dwtModule').service('DwtProgressService', [
  '$sessionStorage',
  '$filter',
  function ($sessionStorage, $filter) {
    this.getDWTScheduleProgressData = function (dwtScheduleInfo) {
      var progressData = [];
      var dividendPaymentDate = this.isPastDue(
        dwtScheduleInfo.dividendPaymentDate
      );
      var dividendRecordDate = this.isPastDue(
        dwtScheduleInfo.dividendRecordDate
      );
      var qrfReclaimStartDate = this.isPastDue(
        dwtScheduleInfo.qrfReclaimStartDate
      );
      var qrfReclaimEndDate = this.isPastDue(dwtScheduleInfo.qrfReclaimEndDate);
      if (dwtScheduleInfo.peStartDate && dwtScheduleInfo.peEndDate) {
        var peStartDate = this.isPastDue(dwtScheduleInfo.peStartDate);
        var peEndDate = this.isPastDue(dwtScheduleInfo.peEndDate);
      }
      var rePaymentTaxDate = this.isPastDue(dwtScheduleInfo.rePaymentTaxDate);
      if (dwtScheduleInfo.dividendPaymentDate !== '01 Jan 1970') {
        progressData.push({
          label: 'label_dividendPaymentDate',
          value: dwtScheduleInfo.dividendPaymentDate,
          isPastDue: dividendPaymentDate,
        });
      }
      if (dwtScheduleInfo.dividendRecordDate !== '01 Jan 1970') {
        progressData.push({
          label: 'label_dividendRecordDate',
          value: dwtScheduleInfo.dividendRecordDate,
          isPastDue: dividendRecordDate,
        });
      }
      if (dwtScheduleInfo.qrfReclaimStartDate !== '01 Jan 1970') {
        progressData.push({
          label: 'label_QRFReclaimStartDate',
          value: dwtScheduleInfo.qrfReclaimStartDate,
          isPastDue: qrfReclaimStartDate,
        });
      }
      if (dwtScheduleInfo.qrfReclaimEndDate !== '01 Jan 1970 00:00') {
        progressData.push({
          label: 'label_QRFReclaimEndDate',
          value: dwtScheduleInfo.qrfReclaimEndDate,
          isPastDue: qrfReclaimEndDate,
        });
      }
      if (
        dwtScheduleInfo.peStartDate &&
        dwtScheduleInfo.peStartDate !== '01 Jan 1970'
      ) {
        progressData.push({
          label: 'label_PEReclaimStartDate',
          value: dwtScheduleInfo.peStartDate,
          isPastDue: peStartDate,
        });
      }
      if (
        dwtScheduleInfo.peEndDate &&
        dwtScheduleInfo.peEndDate !== '01 Jan 1970 00:00'
      ) {
        progressData.push({
          label: 'label_PEReclaimEndDate',
          value: dwtScheduleInfo.peEndDate,
          isPastDue: peEndDate,
        });
      }
      if (dwtScheduleInfo.rePaymentTaxDate !== '01 Jan 1970') {
        progressData.push({
          label: 'label_dateRepaymentTaxAuthority',
          value: dwtScheduleInfo.rePaymentTaxDate,
          isPastDue: rePaymentTaxDate,
        });
      }

      angular.forEach(progressData, function (data) {
        data.dateValue = new Date(data.value);
      });
      var sortedArray = $filter('orderBy')(progressData, 'dateValue', false);
      angular.forEach(sortedArray, function (data) {
        if (
          (data.label === 'label_PEReclaimEndDate' &&
            data.value !== undefined) ||
          (data.label === 'label_QRFReclaimEndDate' && data.value !== undefined)
        ) {
          data.value = data.value + ' ' + 'CET';
        }
      });
      return sortedArray;
    };

    this.getProgressClass = function (isPastDue, index) {
      var data = [];
      if (isPastDue) data.push('success-color');
      else data.push('warning-color');
      if (index === 0) {
        data.push('colOne');
      }
      if (index === 1) {
        data.push('colTwo');
      }
      if (index === 2) {
        data.push('colThree');
      }
      if (index === 3) {
        data.push('colFour');
      }
      if (index === 4) {
        data.push('colFive');
      }
      if (index === 5) {
        data.push('colSix');
      }
      if (index === 6) {
        data.push('colSeven');
      }
      if (index === 7) {
        data.push('colEight');
      }
      if (index === 8) {
        data.push('colNine');
      }
      if (index === 9) {
        data.push('colTen');
      }

      return data;
    };

    this.isPastDue = function (dateString) {
      var d = new Date(dateString);
      var n = d.getTime();

      var today = new Date();
      var currentTime = today.getTime();
      return currentTime >= n;
    };
  },
]);
