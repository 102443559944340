import {
  MenuItem,
  HOME_LABELS,
  ACCOUNT_LABELS,
  GM_LABELS,
  DWT_LABELS,
  SHID_LABELS,
} from './_common';

export function getAgentMenuItems(lang, userRoleProductCodes, { isAdmin }) {
  const labels = {
    home: HOME_LABELS,
    accounts: ACCOUNT_LABELS,
    gm: GM_LABELS,
    tax: DWT_LABELS,
    shid: SHID_LABELS,
  };

  const menus = [
    new MenuItem(labels.home[lang], 'actionHome', 0),
    new MenuItem(labels.accounts[lang], 'myAccounts', 4),
  ];

  //logic for adding GM menu in agent.
  if (userRoleProductCodes.includes('GM')) {
    menus.push(new MenuItem(labels.gm[lang], 'agentGeneralMeetingHome', 1));
  }

  if (userRoleProductCodes.includes('DWT')) {
    const index = isAdmin === 'R' ? 0 : 2;
    menus.push(new MenuItem(labels.tax[lang], 'taxReclaimHome', index));
  }

  if (userRoleProductCodes.includes('SHID')) {
    menus.push(new MenuItem(labels.shid[lang], 'shareholders-id', 3));
  }

  return menus;
}
