/**
 * @ngdoc factory
 * @name generalMeetingHomeModule.ExtensionRequestService
 * @description This is a factory used for extension request related REST calls
 * @requires $http for POST request
 * @requires $resource for other REST calls.
 */
(function () {
  'use strict';
  angular
    .module('generalMeetingHomeModule')
    .factory('ExtensionRequestService', [
      '$http',
      '$resource',
      function ($http, $resource) {
        return {
          createExtensionRequest: function (data, authtoken) {
            return $http({
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Internal-Auth-Token': authtoken,
              },
              url: 'v1/gm/extension-requests',
              method: 'POST',
              data: data,
            });
          },
          updateExtensionRequest: function (
            extensionRequestId,
            data,
            authtoken
          ) {
            return $http({
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Internal-Auth-Token': authtoken,
              },
              url: 'v1/gm/extension-requests/' + extensionRequestId,
              method: 'PUT',
              data: data,
            });
          },
          getExtensionReqStatus: function (meetingId, intermediaryId) {
            return $resource(
              'v1/extensionrequest/' + intermediaryId + '/' + meetingId,
              {},
              {
                query: {
                  method: 'GET',
                  isArray: false,
                },
              }
            );
          },
          getIntermediaryExtnReqStatus: function (meetingId, intermediaryId) {
            return $resource(
              'v1/extensionrequest/' +
                intermediaryId +
                '/' +
                meetingId +
                '/isExtReqApproved',
              {},
              {
                query: {
                  method: 'GET',
                  isArray: false,
                },
              }
            );
          },
        };
      },
    ]);
})();
