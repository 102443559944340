(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name : dwt create QRF service
   * @returns : rest calls to create QRF detail, creating JSON request objects for service calls.
   * @description : Service to view detail.
   */
  angular.module('dwtCreateQRFClaimModule').factory('CreateDwtQRFReqService', [
    '$http',
    '$resource',
    function ($http, $resource) {
      return {
        getCreateGMDropDown: function (languageCode) {
          return $resource(
            '/v2/common/open/countries',
            {
              params: {
                languageCode: languageCode,
              },
            },
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        createDwtQRFRequest: function (data) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/dwtclaims/' + data.eventId + '/QRF',
            method: 'POST',
            data: data,
          });
        },
        searchSans: function (data) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/dwtclaims/PE/' +
              data.intermediaryId +
              '/' +
              data.securityAccountNo +
              '/data',
            method: 'GET',
            data: data,
          });
        },
        editDWTQRFRequest: function (data, eventId, claimId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/dwtevents/' + eventId + '/dwtclaims/' + claimId,
            method: 'PUT',
            data: data,
          });
        },
        getPaymentRefrenceNumber: function (intermediaryId, eventId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/dwtclaims/' + intermediaryId + '/' + eventId + '/home',
            method: 'GET',
          });
        },
      };
    },
  ]);
})();
