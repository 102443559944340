(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name : gmShareClassFormDataService
   * @description : Service function for creating shareclass form data
   */
  angular.module('commonModule').factory('gmShareClassFormDataService', [
    '$filter',
    function ($filter) {
      return {
        addNewShareClass: function (
            vCList,
            additionalShareClasses,
            lang,
            agentIssuerList,
            dutchEnabled,
            frenchEnabled,
            germanEnabled,
            spanishEnabled
        ) {
          additionalShareClasses.push({
            isAuthorizedVcSelected: false,
            enrollId: null,
            shareClassId: null,
            csdId: null,
            prefixNumber: null,
            isin: 'Not Listed',
            totalEntitleVote: '',
            voteRightPerShare: 1,
            totalVotingRight: '',
            maxNumberOfARAllowed: '',
            votingInstructionIntermediary: false,
            votingInstructionShareholder: false,
            votingInstructionShareplanholder: false,
            votingByCorrespondenceShareholder: false,
            votingByCorrespondenceShareplanholder: false,
            virtualParticipationShareholder: false,
            virtualParticipationShareplanholder: false,
            requestAttendenceCardShareholder: false,
            requestAttendenceCardShareplanholder: false,
            regVoteCollectorWrkst: false,
            agentItself: false,
            issuerItself: false,
            scMeetingIntermediaryAssocVOList: [],
            authorizedVoteCollectorList: [],
            shareClassNameVoList: [
              {
                languageCode: 'EN',
                shareClassName: null,
              },
            ],
          });
          angular.forEach(agentIssuerList, function (value) {
            additionalShareClasses[
            additionalShareClasses.length - 1
                ].authorizedVoteCollectorList.push({
              isAgent: value.isAgent,
              isDeleted: true,
              isIssuer: value.isIssuer,
              isVoteConfirmed: value.isVoteConfirmed,
              lastName: value.lastName,
              vcEmailID: value.vcEmailID,
              vcID: value.vcID,
              vcUserIdWoSalt: value.vcUserIdWoSalt,
              vcName: value.vcName,
              userGroupID: value.userGroupID,
              pseudoVcGroup: value.pseudoVcGroup,
            });
          });
          angular.forEach(vCList, function (value) {
            additionalShareClasses[
            additionalShareClasses.length - 1
                ].authorizedVoteCollectorList.push({
              vcID: value.vcID,
              vcUserIdWoSalt: value.vcUserIdWoSalt,
              isDeleted: true,
              isAgent: false,
              isIssuer: false,
              isVoteConfirmed: false,
              vcName: value.vcName,
              vcEmailID: value.vcEmailID,
              userGroupID: value.userGroupID,
              pseudoVcGroup: value.pseudoVcGroup,
            });
          });
          if (dutchEnabled) {
            additionalShareClasses[
            additionalShareClasses.length - 1
                ].shareClassNameVoList.push({
              languageCode: 'NL',
              shareClassName: null,
            });
          }
          if (frenchEnabled) {
            additionalShareClasses[
            additionalShareClasses.length - 1
                ].shareClassNameVoList.push({
              languageCode: 'FR',
              shareClassName: null,
            });
          }
          if (germanEnabled) {
            additionalShareClasses[
            additionalShareClasses.length - 1
                ].shareClassNameVoList.push({
              languageCode: 'DE',
              shareClassName: null,
            });
          }
          if (spanishEnabled) {
            additionalShareClasses[
            additionalShareClasses.length - 1
                ].shareClassNameVoList.push({
              languageCode: 'ES',
              shareClassName: null,
            });
          }

          var filteredArray = $filter('orderBy')(
              additionalShareClasses[additionalShareClasses.length - 1]
                  .shareClassNameVoList,
              function (records) {
                return records.languageCode !== lang;
              }
          );
          additionalShareClasses[
          additionalShareClasses.length - 1
              ].shareClassNameVoList = filteredArray;
          return additionalShareClasses;
        }
      };
    },
  ]);
})();

