import { MenuItem, HOME_LABELS, MEETING_LABELS } from './_common';

export function getPublicMenuItems(lang = 'EN') {
  const labels = {
    home: HOME_LABELS,
    meetings: MEETING_LABELS,
    register: {
      EN: 'Register',
      NL: 'Registreer',
      FR: 'Inscription',
      DE: 'Registrieren',
      ES: 'Registrar'
    },
  };

  return [
    new MenuItem(labels.home[lang], 'shareholder', 0),
    new MenuItem(labels.meetings[lang], 'shGeneralMeetings', 1),
    new MenuItem(labels.register[lang], 'registerSH', 2),
  ];
}
