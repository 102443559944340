angular.module('generalMeetingMainModule').filter('angentStatus', function () {
  return function (item) {
    var statusMap = {};
    statusMap['Y'] = 'Yes';
    statusMap['N'] = 'No';
    statusMap['D'] = 'Draft';
    statusMap['P'] = 'Pending';
    statusMap['T'] = 'To be published';
    statusMap['L'] = 'Live';
    statusMap['M'] = 'Completed';
    statusMap['C'] = 'Cancelled';
    statusMap['CN'] = 'Cancelled';
    statusMap['A'] = 'Approved';
    statusMap['R'] = 'Rejected';
    if (statusMap[item]) {
      return statusMap[item];
    } else {
      return item;
    }
  };
}).filter('workstationId', function () {
  return function (wsIds) {
    var workstationIdsMap = {};
    workstationIdsMap['1'] = 'Agent';
    workstationIdsMap['2'] = 'Issuer';
    workstationIdsMap['3'] = 'Shareplan';
    workstationIdsMap['4'] = 'Shareholder';
    workstationIdsMap['5'] = 'VoteCollector';
    workstationIdsMap['6'] = 'Intermediary';
    if (workstationIdsMap[wsIds]) {
      return workstationIdsMap[wsIds];
    } else {
      return wsIds;
    }
  };
}).filter(
    'firstLetterCapitalize',
    function () {
      return function (input) {
        return (!!input) ? input.charAt(0).toUpperCase()
            + input.substr(1).toLowerCase() : '';
      };
    }).filter('emailBodyToPlaintext', function () {
  return function (text) {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  };
});
