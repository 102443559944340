/**
 * @ngdoc factory
 * @name shareHolderModule.shareHolderService
 * @description This is a factory used for shareholder module related REST calls
 * @requires $http for POST request
 * @requires $resource for the REST calls
 * @requires $$sessionStorage to store/pass session data
 */
(function () {
  'use strict';
  angular.module('shareHolderModule').factory('shareHolderService', [
    '$http',
    '$resource',
    '$sessionStorage',
    function ($http, $resource, $sessionStorage) {
      return {
        getSHGeneralMeetings: function () {
          return $resource(
            'v1/portalusers/' +
              $sessionStorage.usertype +
              '/' +
              $sessionStorage.userId +
              '/1/500/generelMeetings',
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getIssuerLogos: function (pageNo) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/shareholderusers/issuerLogos/' + pageNo + '/40',
            method: 'GET',
          });
        },
        getSHProfileData: function (langCode) {
          return $resource(
            'v1/profiles/' + $sessionStorage.usertype + '/' + langCode,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        updateSHProfileData: function (data) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/profiles',
            method: 'PUT',
            data: data,
          });
        },
        getUpcomingMeetings: function (maxResults) {
          return $resource(
            '/v2/public/general-meetings?maxResults=' + maxResults,
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        saveAttendanceRequestShareholder: function (
          attendanceRequestData,
          authtoken
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/generalmeetings/registration/attendances',
            method: 'POST',
            data: attendanceRequestData,
          });
        },
        getAvailableProxyList: function (shareholderId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/shareholderusers/' + shareholderId,
            method: 'GET',
          });
        },
        getBulkUploadARDetails: function (reqId, languageCode, workStation) {
          return $resource(
            'v1/generalmeetings/registration/' +
              reqId +
              '/' +
              languageCode +
              '/' +
              workStation,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        registerRequest: function (languageCode) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/shareholder/registrations/' + languageCode,
            method: 'GET',
          });
        },

        submitRegistration: function (requestObj) {
          return $http({
            header: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/shareholder/registrations',
            method: 'POST',
            data: requestObj,
          });
        },

        validateVirtualParticipationRequest: function (requestObj) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/virtualmeetingregistration/validateNoOfSharesAndSAN',
            method: 'POST',
            data: requestObj,
          });
        },

        registerVirtualParticipationRequest: function (registerObj) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/virtualmeetingregistration/register',
            method: 'POST',
            data: registerObj,
          });
        },

        acknowledgeVirtualParticipationRequest: function (acknowledgeObj) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/virtualmeetingregistration/acknowledge',
            method: 'POST',
            data: acknowledgeObj,
          });
        },

        resendOtp: function (resendOtpObj) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/virtualmeetingregistration/resend',
            method: 'POST',
            data: resendOtpObj,
          });
        },

        getAuthenticationOtp: function (twoFaOtpObj) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/virtualmeetingparticipation/sendOtp',
            method: 'POST',
            data: twoFaOtpObj,
          });
        },

        authenticationResendOtp: function (authenticationReSendOtpObj) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/virtualmeetingparticipation/resendOtp',
            method: 'POST',
            data: authenticationReSendOtpObj,
          });
        },
        authenticationVerifyOtp: function (authenticationVerifyOtpObj) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/virtualmeetingparticipation/verifyOtp ',
            method: 'POST',
            data: authenticationVerifyOtpObj,
          });
        },
        joinMeetingStatus: function (meetingId, virtualRequestId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/virtualmeetingparticipation/joinMeeting/' +
              meetingId +
              '/' +
              virtualRequestId,
            method: 'GET',
          });
        },
        getShareholderShareClassList: function (meetingId, lang) {
          return $resource(
            'v1/virtualmeetingregistration/generalmeeting?gmId=' +
              meetingId +
              '&langCode=' +
              lang,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        editVirtualAttendanceRequest: function (virtualRqId, languageCode) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/virtualmeetingregistration/' +
              virtualRqId +
              '?&langCode=' +
              languageCode,
            method: 'GET',
          });
        },
        updateVirtualParticipationRequest: function (registerObj) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/virtualmeetingregistration/register',
            method: 'PUT',
            data: registerObj,
          });
        },
      };
    },
  ]);
})();
