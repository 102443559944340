/**
 * @ngdoc service
 * @name generalMeetingHomeModule.GeneralMeetingDataService
 * @requires GeneralMeetingProgressService, generalMeetingService
 * @description This is a service used to populate general meeting data for general meeting view
 */
angular
  .module('generalMeetingHomeModule')
  .service('GeneralMeetingDataService', [
    'GeneralMeetingProgressService',
    'generalMeetingService',
    function (GeneralMeetingProgressService, generalMeetingService) {
      this.getGeneralMeetingData = function () {
        return generalMeetingService.getGeneralMeetingsHome();
      };
      this.getGeneralMeetingDataAgent = function (
        pageNumber,
        pageSize,
        event,
        sortDirection,
        sortBy,
        searchParam
      ) {
        return generalMeetingService.getGeneralMeetingsAgent(
          pageNumber,
          pageSize,
          event,
          sortDirection,
          sortBy,
          searchParam
        );
      };
      this.getGeneralMeetingDataByWorkstation = function (workstation) {
        if (workstation === 'AG') {
          return generalMeetingService.getGeneralMeetingsHome();
        }
      };
    },
  ]);
