(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name : view dwt extn service
   * @returns : rest calls to view dwt extn, creating JSON request objects for service calls.
   * @description : Service to view dwt extn.
   */
  angular.module('dwtModule').factory('DwtHomeService', [
    '$http',
    '$sessionStorage',
    function ($http, $sessionStorage) {
      return {
        dwtHome: function () {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/dwtevents/list/' + $sessionStorage.usertype + '/1/500',
            method: 'GET',
            data: '',
          });
        },
        getLiveEventDetailedOverView: function () {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/dwtevents/' +
              $sessionStorage.usertype +
              '/1/500/liveEventsSummary',
            method: 'GET',
            data: '',
          });
        },
      };
    },
  ]);
})();
