/**
 * @ngdoc factory
 * @name commonModule.AuthService
 * @description This is a factory used for shareholder module related REST calls
 * @requires $http for POST request
 * @requires $sessionStorage to store/pass session data
 * @requires $log for debug logs
 */
(function() {
  'use strict';

  angular.module('commonModule').factory(
          'AuthService',
          [
              '$http',
              '$sessionStorage',
              '$log',
              '$resource',
              function($http, $sessionStorage, $log, $resource) {

                return {
                  login: function(data) {
                    return $http.post('v1/login/authenticate', data);
                  },
                  logout: function() {
                    return $http.post('logout');
                  },
                  sessionReload: function() {
                    return $resource('v1/session/reload', {}, {
                      'query': {
                        method: 'GET',
                        isArray: false
                      }
                    });
                  },
                  loginURL : function(url) {
                    var isLoginURL = false;
                    var wsHome = '';
                    if (url !== null) {
                      wsHome = url.substring(url.lastIndexOf('/'));
                    }
                    isLoginURL = (wsHome === '/agent')
                            || (wsHome === '/issuer')
                            || (wsHome.indexOf('/issuer?') !== -1)
                            || (wsHome.indexOf('/agent?') !== -1)
                            || (wsHome.indexOf('/intermediary?') !== -1)
                            || (wsHome === '/intermediary')
                            || (wsHome === '/votecollector')
                            || (wsHome === '/shareholderlogin')
                            || (wsHome === '/shareplan')
                            || (wsHome.indexOf('/shareplan') !== -1)
                            || (wsHome === '/shareholder')
                            || wsHome.indexOf('/tokenlogin') !== -1;
                    return isLoginURL;
                  },
                  publicURL: function() {
                    var isPublicURL = false;
                    var pathList = window.location.pathname.split('/');
                    isPublicURL = (pathList[1] === 'shareholder')
                            || (pathList[1] === 'registerSH')
                            || (pathList[1] === 'shGeneralMeetings')
                            || (pathList[1] === 'shHomeGeneralMeetings')
                            || (pathList[1] === 'shareholderlogin')
                            || (pathList[1] === 'generalMeetings')
                            || (pathList[1] === 'termsUse')
                            || (pathList[1] === 'privacyPolicy')
                            || (pathList[1] === 'contactDetails');
                    return isPublicURL;
                  },
                          userLoggedIn: function() {
                            var pathList = window.location.pathname.split('/');
                            if (pathList[1] === 'shareholder') {
                              return false;
                            } else {
                              return ($sessionStorage.userId !== null
                                      && $sessionStorage.userId !== undefined && $sessionStorage.userId !== '');
                            }
                          }
                        };

              }]);

})();
