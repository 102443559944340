(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name : view dwt detail service
   * @returns : rest calls to view dwt detail, creating JSON request objects for service calls.
   * @description : Service to view dwt detail.
   */
  angular
    .module('dwtCreateExtensionModule')
    .factory('CreateDwtExtensionReqService', [
      '$http',
      '$localStorage',
      '$sessionStorage',
      '$resource',
      function ($http, $localStorage, $sessionStorage, $resource) {
        return {
          createDwtExtensionRequest: function (data) {
            return $http({
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              url: 'v1/dwt/extension-requests',
              method: 'POST',
              data: data,
            });
          },
          updateDwtExtensionRequest: function (extensionRequestId, data) {
            return $http({
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              url: 'v1/dwt/extension-requests/' + extensionRequestId,
              method: 'PUT',
              data: data,
            });
          },
        };
      },
    ]);
})();
