import appConstant from '@/global/constants';

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name : gmAgendaFormDataService
   * @description : Service function for creating agenda form data
   */
  angular.module('commonModule').factory('gmAgendaFormDataService', [
    '$filter',
    function ($filter) {
      return {
        createAgendaFormData: function (
            userType,
            loggedInUserID,
            formLanguage,
            generalMeetingId,
            gm,
            issuerId,
            selectedEnglish,
            selectedDutch,
            selectedGerman,
            selectedFrench,
            selectedSpanish
        ) {
          var agendaFormData = new FormData();
          agendaFormData.append('loggedInUserID', loggedInUserID);
          agendaFormData.append('workstation', userType);
          agendaFormData.append('languageCode', formLanguage);
          agendaFormData.append('generalMeetingId', generalMeetingId);
          agendaFormData.append('issuerId', issuerId);
          agendaFormData.append('status', gm.createData.status);
          // supported language
          agendaFormData.append('supportedLanguages[0].index', '1');
          agendaFormData.append(
              'supportedLanguages[0].isChecked',
              selectedEnglish
          );
          agendaFormData.append(
              'supportedLanguages[0].languageCode',
              appConstant.OPTED_EN
          );
          agendaFormData.append('supportedLanguages[1].index', '2');
          agendaFormData.append(
              'supportedLanguages[1].isChecked',
              selectedDutch
          );
          agendaFormData.append(
              'supportedLanguages[1].languageCode',
              appConstant.OPTED_NL
          );
          agendaFormData.append('supportedLanguages[2].index', '3');
          agendaFormData.append(
              'supportedLanguages[2].isChecked',
              selectedFrench
          );
          agendaFormData.append(
              'supportedLanguages[2].languageCode',
              appConstant.OPTED_FR
          );
          agendaFormData.append('supportedLanguages[3].index', '4');
          agendaFormData.append(
              'supportedLanguages[3].isChecked',
              selectedGerman
          );
          agendaFormData.append(
              'supportedLanguages[3].languageCode',
              appConstant.OPTED_DE
          );
          agendaFormData.append('supportedLanguages[4].index', '5');
          agendaFormData.append(
              'supportedLanguages[4].isChecked',
              selectedSpanish
          );
          agendaFormData.append(
              'supportedLanguages[4].languageCode',
              appConstant.OPTED_ES
          );
          if (
              gm.DynamicAgendaItems != null &&
              gm.DynamicAgendaItems.length > 0
          ) {
            angular.forEach(gm.DynamicAgendaItems, function (value, key) {
              if (value.agendaId !== null) {
                agendaFormData.append(
                    'gmAgendaVO[' + key + '].agendaId',
                    value.agendaId
                );
              }
              var adKey = 0;
              var tempAgArray = null;

              function createAgendaItem(lang) {
                tempAgArray = $filter('filter')(value.agendaDetailVOList, {
                  languageCode: lang,
                });
                if (tempAgArray.length > 0) {
                  agendaFormData.append(
                      'gmAgendaVO[' +
                      key +
                      '].agendaDetailVOList[' +
                      adKey +
                      '].languageCode',
                      lang
                  );
                  if (tempAgArray[0].file !== null) {
                    agendaFormData.append(
                        'gmAgendaVO[' +
                        key +
                        '].agendaDetailVOList[' +
                        adKey +
                        '].file',
                        tempAgArray[0].file
                    );
                  }
                  if (tempAgArray[0].fileName === undefined) {
                    agendaFormData.append(
                        'gmAgendaVO[' +
                        key +
                        '].agendaDetailVOList[' +
                        adKey +
                        '].fileName',
                        ''
                    );
                  } else {
                    agendaFormData.append(
                        'gmAgendaVO[' +
                        key +
                        '].agendaDetailVOList[' +
                        adKey +
                        '].fileName',
                        tempAgArray[0].fileName
                    );
                  }
                  agendaFormData.append(
                      'gmAgendaVO[' +
                      key +
                      '].agendaDetailVOList[' +
                      adKey +
                      '].agendaExplanation',
                      tempAgArray[0].agendaExplanation
                  );
                  agendaFormData.append(
                      'gmAgendaVO[' +
                      key +
                      '].agendaDetailVOList[' +
                      adKey +
                      '].agendaTitle',
                      tempAgArray[0].agendaTitle
                  );
                  adKey++;
                }
              }

              if (selectedEnglish) {
                createAgendaItem('EN');
              }
              if (selectedDutch) {
                createAgendaItem('NL');
              }
              if (selectedFrench) {
                createAgendaItem('FR');
              }
              if (selectedGerman) {
                createAgendaItem('DE');
              }
              if (selectedSpanish) {
                createAgendaItem('ES');
              }
              agendaFormData.append(
                  'gmAgendaVO[' + key + '].serialNo',
                  value.serialNo
              );
              agendaFormData.append('gmAgendaVO[' + key + '].agendaNo', key);
              agendaFormData.append(
                  'gmAgendaVO[' + key + '].isVoteable',
                  value.isVoteable
              );
              agendaFormData.append(
                  'gmAgendaVO[' + key + '].isDeleted',
                  value.isDeleted
              );
            });
          }
          return agendaFormData;
        }
      };
    },
  ]);
})();

