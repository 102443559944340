/**
 * @ngdoc factory
 * @name commonModule.AuditTrailService
 * @description This is a factory used for audit and trail related REST calls
 * @requires $http for POST request
 * @requires $resource for other REST calls.
 */
(function() {
  'use strict';

  angular.module('commonModule')
          .factory(
                  'AuditTrailService',
                  [
                      '$http',
                      '$resource',
                      '$log',
                      function($http, $resource, $log) {
                        return {
                          getActionDropDown: function() {
                            return $resource('v1/audittrails/data/GM', {}, {
                              post: {
                                method: 'POST'
                              }
                            });
                          },
                          getAllAuditTrailsData: function(pageFrom) {
                            return $resource('v1/audittrails/GM/' + pageFrom
                                    + '/100', {}, {
                              post: {
                                method: 'POST'
                              }
                            });
                          },
                          getAllEmailAuditTrailsData: function(pageFrom) {
                            return $resource('v1/emailaudittrails/GM/'
                                    + pageFrom + '/100', {}, {
                              post: {
                                method: 'POST'
                              }
                            });
                          },
                          searchAuditTrail: function(data, authtoken) {
                            $log.debug(data);
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'X-Internal-Auth-Token': authtoken
                              },
                              url: 'v1/audittrails',
                              method: 'POST',
                              data: data
                            });
                          },
                          searchEmailAudit: function(data, authtoken) {
                            $log.debug(data);
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'X-Internal-Auth-Token': authtoken
                              },
                              url: 'v1/emailaudittrails',
                              method: 'POST',
                              data: data
                            });
                          },
                          getTemplateTypes: function() {
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                              },
                              url: 'v1/templates/type',
                              method: 'GET'
                            });
                          },
                          getSubjectOptions: function() {
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                              },
                              url: 'v1/templates/certificatedata',
                              method: 'GET'
                            });
                          },
                          getEmailSubjectOptions: function() {
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                              },
                              url: 'v1/templates/GM/emaildata',
                              method: 'GET'
                            });
                          },
                          getWebSubjectOptions: function() {
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                              },
                              url: 'v1/templates/frontendtext',
                              method: 'GET'
                            });
                          },
                          getIssuerList: function() {
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                              },
                              url: 'v1/templates/issuerList',
                              method: 'GET'
                            });
                          },
                          searchTemplate: function(data) {
                            $log.debug(data);
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                              },
                              url: 'v1/templates/search',
                              method: 'POST',
                              data: data
                            });
                          },
                          updateTemplate: function(data, authtoken) {
                            $log.debug(data);
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'X-Internal-Auth-Token': authtoken
                              },
                              url: 'v1/templates',
                              method: 'PUT',
                              data: data
                            });
                          },
                          deleteTemplate: function(templateType, templateId,
                                  authtoken) {
                            $log.debug(templateType, templateId);
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'X-Internal-Auth-Token': authtoken
                              },
                              url: 'v1/templates/' + templateType + '/'
                                      + templateId + '',
                              method: 'DELETE'
                            });
                          }
                        };
                      }]);
})();
