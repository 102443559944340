/**
 * @ngdoc service
 * @name generalMeetingHomeModule.GeneralMeetingDataTableService
 * @description This is a service used to configure ColumnNames , displayNames and options names/labels like edit, delete,
 *    notification etc for general meeting tables/views
 */

angular
  .module('generalMeetingHomeModule')
  .service('GeneralMeetingDataTableService', [
    function () {
      // Define options across all workstations
      this.optionParams = {};
      this.optionParams['view'] = {
        label: 'label_view',
        className: 'glyphicon ocf-icon-large  ocf-icon-user-group iconclass',
        event: 'view',
      };
      this.optionParams['edit'] = {
        label: 'label_edit',
        className: 'glyphicon ocf-icon-large  ocf-icon-pencil_edit iconclass',
        event: 'edit',
      };
      this.optionParams['delete'] = {
        label: 'label_delete',
        className: 'glyphicon ocf-icon-large ocf-icon-delete iconclass',
        event: 'delete',
      };
      this.optionParams['notification'] = {
        label: 'label_notification',
        className: 'glyphicon ocf-icon-large  icon-notification iconclass',
        event: 'notification',
      };
      this.optionParams['inviteSharePlan'] = {
        label: 'label_inviteShareplan',
        className: 'glyphicon ocf-icon-large  icon-inviteShareplan iconclass',
        event: 'share',
      };
      this.optionParams['approveVC'] = {
        label: 'label_accept',
        className: 'glyphicon ocf-icon-large icon-tick-yes iconclass',
        event: 'approveVC',
      };
      this.optionParams['rejectVC'] = {
        label: 'label_decline',
        className: 'glyphicon ocf-icon-large_no ocf-icon-No iconclass',
        event: 'rejectVC',
      };
      this.optionParams['cancel'] = {
        label: 'label_cancel',
        className: 'glyphicon ocf-icon-large_no ocf-icon-No iconclass',
        event: 'cancel',
      };
      this.optionParams['vote'] = {
        label: 'label_vote',
        className: 'glyphicon ocf-icon-large30 icon-vote iconclass',
        event: 'vote',
      };
      this.optionParams['attend'] = {
        label: 'label_attend',
        className: 'glyphicon ocf-icon-large30 icon-attend iconclass',
        event: 'attend',
      };
      this.optionParams['downloadCertificate'] = {
        label: 'label_downloadCertificate',
        className: 'glyphicon ocf-icon-large download-arrow-button iconclass',
        event: 'downloadCertificate',
      };
      this.optionParams['shareholderEmail'] = {
        label: 'label_shareHolderEmail',
        className: 'glyphicon ocf-icon-large icon-inviteShareplan iconclass',
        event: 'shareholderEmail',
      };
      this.optionParams['createExtensionRequest'] = {
        label: 'label_viewEditExtnReq',
        className: 'glyphicon ocf-icon-large icon-createExtnReq iconclass',
        event: 'createExtensionRequest',
      };
      this.optionParams['createIntermediaryExtensionRequest'] = {
        label: 'label_createExtnReq',
        className: 'glyphicon ocf-icon-large icon-createExtnReq iconclass',
        event: 'createIntermediaryExtensionRequest',
      };
      this.optionParams['createIssuerExtensionRequest'] = {
        label: 'label_viewEditExtnReq',
        className: 'glyphicon ocf-icon-large icon-createExtnReq iconclass',
        event: 'createIssuerExtensionRequest',
      };
      this.optionParams['viewExtensionRequest'] = {
        label: 'label_viewExtnReq',
        className: 'ocf-icon-large fa fa-calendar fa-lg iconclass',
        event: 'createExtensionRequest',
      };
      this.optionParams['viewIntermediaryExtensionRequest'] = {
        label: 'label_viewExtnReq',
        className: 'ocf-icon-large fa fa-calendar fa-lg iconclass',
        event: 'createIntermediaryExtensionRequest',
      };
      this.optionParams['viewIssuerExtensionRequest'] = {
        label: 'label_viewExtnReq',
        className: 'ocf-icon-large fa fa-calendar fa-lg iconclass',
        event: 'createIssuerExtensionRequest',
      };
      this.optionParams['sendEmailEuroclear'] = {
        label: 'label_sendEmailEuro',
        className: 'glyphicon ocf-icon-large img-send-mail-tick  iconclass',
        event: 'sendEmailEuroclear',
      };
      this.optionParams['reSendEmailEuroclear'] = {
        label: 'label_reSendEmailEuro',
        className:
          'glyphicon ocf-icon-large img-send-mail-tick-green  iconclass',
        event: 'reSendEmailEuroclear',
      };
      this.optionParams['downloadIntermediaryInstructions'] = {
        label: 'label_download_intermediary_instructions',
        className: 'glyphicon ocf-icon-large report-icon-button iconclass',
        event: 'downloadIntermediaryInstructions',
      };
      this.optionParams['viewVirtualParticiation'] = {
        label: 'label_virtual_participation',
        className:
          'glyphicon ocf-icon-large virtualparticiaption-icon-button iconclass',
        event: 'viewVirtualParticiation',
      };
      this.getColumnDefinationByWorkStationId = function (workStationId) {
        if (workStationId === 'AG') {
          return [
            {
              columnName: 'label_issuer',
              dataIndex: 'issuerName',
              sortValue: 'issuerName',
              className: 'col-md-2',
              optionColumn: false,
              url: 'generalDetailsView',
              align: 'left',
            },
            {
              columnName: 'label_type',
              dataIndex: 'meetingType',
              sortValue: 'meetingType',
              className: 'col-md-2',
              optionColumn: false,
              align: 'left',
            },
            {
              columnName: 'label_publicationDate',
              dataIndex: 'publicationDateStr',
              sortValue: 'publicationDate',
              className: 'col-md-1',
              optionColumn: false,
            },
            {
              columnName: 'label_recordDate',
              dataIndex: 'recordDateStr',
              sortValue: 'recordDate',
              className: 'col-md-1',
              optionColumn: false,
            },
            {
              columnName: 'label_closingDate',
              dataIndex: 'shareholderDeadlineDateStr',
              sortValue: 'shareholderDeadlineDate',
              className: 'col-md-1',
              optionColumn: false,
            },
            {
              columnName: 'label_intermediaryDeadlineDate',
              dataIndex: 'intermediaryDeadlineDateStr',
              sortValue: 'intermediaryDeadlineDate',
              className: 'col-md-1',
              optionColumn: false,
            },
            {
              columnName: 'label_generalMeetingDate',
              dataIndex: 'generalMeetingDateStr',
              sortValue: 'generalMeetingDate',
              className: 'col-md-1',
              optionColumn: false,
            },
            {
              columnName: 'label_options',
              dataIndex: '',
              sortValue: '',
              className: 'col-md-2',
              optionColumn: true,
              align: 'left',
            },
          ];
        } else if (workStationId === 'IS') {
          return [
            {
              columnName: 'label_type',
              dataIndex: 'meetingType',
              sortValue: 'meetingType',
              className: 'widthP14',
              optionColumn: false,
              url: 'generalDetailsView',
              align: 'left',
            },
            {
              columnName: 'label_publicationDate',
              dataIndex: 'publicationDateStr',
              sortValue: 'publicationDate',
              className: 'widthP11',
              optionColumn: false,
            },
            {
              columnName: 'label_closingDate',
              dataIndex: 'shareholderDeadlineDateStr',
              sortValue: 'shareholderDeadlineDate',
              className: 'widthP11',
              optionColumn: false,
            },
            {
              columnName: 'label_intermediaryDeadlineDate',
              dataIndex: 'intermediaryDeadlineDateStr',
              sortValue: 'intermediaryDeadlineDate',
              className: 'widthP14',
              optionColumn: false,
            },
            {
              columnName: 'label_generalMeetingDate',
              dataIndex: 'generalMeetingDateStr',
              sortValue: 'generalMeetingDate',
              className: 'widthP14',
              optionColumn: false,
            },
            {
              columnName: 'label_options',
              dataIndex: '',
              sortValue: '',
              className: 'widthP14',
              optionColumn: true,
              align: 'left',
            },
          ];
        } else if (workStationId === 'VC') {
          return [
            {
              columnName: 'label_issuer',
              dataIndex: 'issuerName',
              sortValue: 'issuerName',
              className: 'widthP17',
              optionColumn: false,
              url: 'generalDetailsView',
              align: 'left',
            },
            {
              columnName: 'label_generalMeeting1',
              dataIndex: 'meetingName',
              sortValue: 'meetingName',
              className: 'widthP14',
              optionColumn: false,
              align: 'left',
            },
            {
              columnName: 'label_convocationDate',
              dataIndex: 'convocationDateStr',
              sortValue: 'convocationDate',
              className: 'widthP12',
              optionColumn: false,
            },
            {
              columnName: 'label_intermediaryDeadlineDate',
              dataIndex: 'intermediaryDeadlineDateStr',
              sortValue: 'intermediaryDeadlineDate',
              className: 'widthP14',
              optionColumn: false,
            },
            {
              columnName: 'label_generalMeetingDate',
              dataIndex: 'generalMeetingDateStr',
              sortValue: 'generalMeetingDate',
              className: 'widthP14',
              optionColumn: false,
            },
            {
              columnName: 'label_options',
              dataIndex: '',
              sortValue: '',
              className: 'widthP14',
              optionColumn: true,
              align: 'left',
            },
          ];
        } else if (workStationId === 'IN') {
          return [
            {
              columnName: 'label_issuer',
              dataIndex: 'issuerName',
              sortValue: 'issuerName',
              className: 'widthP11',
              optionColumn: false,
              url: 'generalMeetings',
              align: 'left',
            },
            {
              columnName: 'label_registrationDeadlineDate',
              dataIndex: 'registrationReleaseDateStr',
              sortValue: 'registrationReleaseDate',
              className: 'widthP12',
              optionColumn: false,
            },
            {
              columnName: 'label_intermediaryDeadlineDate',
              dataIndex: 'intermediaryDeadlineDateStr',
              sortValue: 'intermediaryDeadlineDate',
              className: 'widthP12',
              optionColumn: false,
            },
            {
              columnName: 'label_generalMeetingDate',
              dataIndex: 'generalMeetingDateStr',
              sortValue: 'generalMeetingDate',
              className: 'widthP14',
              optionColumn: false,
            },
            {
              columnName: 'label_options',
              dataIndex: '',
              sortValue: '',
              className: 'widthP17',
              optionColumn: true,
              align: 'left',
            },
          ];
        } else {
          return [];
        }
      };
      this.getFilterButtonsByWorkStationId = function (workStationId) {
        if (workStationId === 'AG') {
          return [
            {
              displayName: 'button0',
              code: 'D',
              showCount: true,
              showOptions: true,
              options: [this.optionParams['edit'], this.optionParams['delete']],
            },
            {
              displayName: 'button1',
              code: 'P',
              showCount: true,
              showOptions: true,
              options: [this.optionParams['edit'], this.optionParams['cancel']],
            },
            {
              displayName: 'button2',
              code: 'T',
              showCount: true,
              showOptions: true,
              options: [this.optionParams['edit'], this.optionParams['cancel']],
            },
            {
              displayName: 'button3',
              code: 'L',
              showCount: true,
              showOptions: true,
              options: [
                this.optionParams['edit'],
                this.optionParams['notification'],
                this.optionParams['inviteSharePlan'],
                this.optionParams['createExtensionRequest'],
                this.optionParams['sendEmailEuroclear'],
                this.optionParams['reSendEmailEuroclear'],
              ],
            },
            {
              displayName: 'button4',
              code: 'M',
              showCount: false,
              showOptions: true,
              options: [
                this.optionParams['viewExtensionRequest'],
                this.optionParams['sendEmailEuroclear'],
                this.optionParams['reSendEmailEuroclear'],
              ],
            },
            {
              displayName: 'button5',
              code: 'C',
              showCount: false,
              showOptions: false,
            },
          ];
        } else if (workStationId === 'VC') {
          return [
            {
              displayName: 'button3',
              code: '!M',
              showCount: true,
              showOptions: true,
              size: 2,
              options: [this.optionParams['approveVC']],
            },
            {
              displayName: 'voteCollectorbutton2',
              code: 'M',
              showCount: true,
              showOptions: true,
              size: 2,
              options: [this.optionParams['approveVC']],
            },
          ];
        } else if (workStationId === 'IS') {
          return [
            {
              displayName: 'button1',
              code: 'P',
              showCount: true,
              showOptions: true,
              options: [this.optionParams['view']],
            },
            {
              displayName: 'button2',
              code: 'T',
              showCount: true,
              showOptions: false,
            },
            {
              displayName: 'button3',
              code: 'L',
              showCount: true,
              showOptions: true,
              options: [this.optionParams['createIssuerExtensionRequest']],
            },
            {
              displayName: 'button4',
              code: 'M',
              showCount: false,
              showOptions: true,
              options: [this.optionParams['viewIssuerExtensionRequest']],
            },
            {
              displayName: 'button5',
              code: 'C',
              showCount: false,
              showOptions: false,
            },
          ];
        } else if (workStationId === 'IN') {
          return [
            {
              displayName: 'button3',
              code: 'L',
              showCount: true,
              showOptions: true,
              options: [
                this.optionParams['vote'],
                this.optionParams['attend'],
                this.optionParams['viewVirtualParticiation'],
                this.optionParams['downloadIntermediaryInstructions'],
                this.optionParams['downloadCertificate'],
                this.optionParams['shareholderEmail'],
                this.optionParams['createIntermediaryExtensionRequest'],
              ],
            },
            {
              displayName: 'button4',
              code: 'M',
              showCount: false,
              showOptions: true,
              options: [
                this.optionParams['vote'],
                this.optionParams['attend'],
                this.optionParams['viewVirtualParticiation'],
                this.optionParams['downloadIntermediaryInstructions'],
                this.optionParams['viewIntermediaryExtensionRequest'],
              ],
            },
          ];
        } else {
          return [];
        }
      };
    },
  ]);
