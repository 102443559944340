/**
 * @ngdoc factory
 * @name shareHolderModule.myVoteService
 * @description This is a factory used for shareholder module related REST calls
 * @requires $http for POST request
 * @requires $resource for the REST calls
 * @requires $sessionStorage to store/pass session data
 */
(function () {
  'use strict';
  angular.module('shareHolderModule').factory('myVoteService', [
    '$http',
    '$resource',
    '$sessionStorage',
    function ($http, $resource, $sessionStorage) {
      return {
        getMyVoteList: function (langCode) {
          return $resource(
              'v2/my-meeting-participations' +
              '/1/500' +
              '?langCode=' +
              langCode,
              {},
              {
                query: {
                  method: 'GET',
                  isArray: false,
                },
              }
          );
        },
        getVirtualParticipationReqList: function () {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              '/v2/gm/shareholder/virtual-attendance-requests?' +
              `pageNo=1&pageSize=500&timestamp=${new Date().toISOString()}`,
            method: 'GET',
          });
        },
        getIntermediaryDetails: function (identificationNumber, type) {
          return $resource(
            'v1/voteinstructions/intermediary/' +
              identificationNumber +
              '/' +
              $sessionStorage.userId +
              '/' +
              type,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        cancelRequest: function (reqId, reqType, status, langCode, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url:
              'v1/voteinstructions/' +
              reqId +
              '/' +
              reqType +
              '/' +
              status +
              '/' +
              langCode +
              '/cancelrequest',
            method: 'GET',
          });
        },
      };
    },
  ]);
})();
