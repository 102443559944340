/**
 * @ngdoc factory
 * @name commonModule.voteCollectorService
 * @description This is a factory used for shareholder module related REST calls
 * @requires $http for POST request
 * @requires $resource for other REST calls.
 * @requires $sessionStorage to store/pass data in session
 */
(function () {
  'use strict';
  angular.module('commonModule').factory('voteCollectorService', [
    '$http',
    '$resource',
    '$sessionStorage',
    function ($http, $resource, $sessionStorage) {
      return {
        getVoteCollectorInfo: function (
          languageCode,
          shareClassId,
          meetingId,
          userType,
          userId
        ) {
          return $resource(
            'v1/generalmeetingdetails/' +
              userId +
              '/' +
              userType +
              '/' +
              meetingId +
              '/votecollectors/' +
              shareClassId +
              '/' +
              languageCode,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getVotingConfirmationExampleTemplate: function (
          workstation = 'vote-collector',
          generalMeetingId,
          shareClassId,
          templateUserType
        ) {
          let templateUser;
          switch (templateUserType) {
            case 'IN':
              templateUser = 'intermediary';
              break;
            case 'SH':
              templateUser = 'shareholder';
              break;
            default:
              throw new Error('Invalid templateUserType');
          }

          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: `/v2/gm/${workstation}/general-meetings/${generalMeetingId}/shareclasses/${shareClassId}/vote-instruction-execution-confirmation/template/${templateUser}`,
            method: 'GET',
          });
        },
        /**
         *
         * @param {string} gmId
         * @param {string} shareClassId
         * @param {date} dateOfSubmission
         * @param {object} emailContent
         * @param {string} emailContent.subject
         * @param {string} emailContent.body
         */
        confirmVotingInstructions(
          gmId,
          shareClassId,
          dateOfSubmission,
          emailContent
        ) {},
        getVotingConfirmationTemplate: function (
          languageCode,
          data,
          authtoken
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/votingconfirmations/' + languageCode,
            method: 'POST',
            data: data,
          });
        },
        getVCStaticText: function (
          userType,
          screenName,
          screenLocation,
          langCode
        ) {
          return $resource(
            'v1/webtemplates/' +
              userType +
              '/' +
              screenName +
              '/' +
              screenLocation +
              '/' +
              langCode,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getExecutionId: function (downloadUrl) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: downloadUrl,
            method: 'GET',
          });
        },
        downloadProgressService: function (serviceurl) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: serviceurl,
            method: 'GET',
          });
        },
        gmVCApproveReject: function (meetingId, decision, authtoken, callback) {
          var response = $resource(
            'v1/votingconfirmations/votecollector/' +
              $sessionStorage.userId +
              '/' +
              meetingId +
              '/' +
              decision,
            {},
            {
              save: {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'X-Internal-Auth-Token': authtoken,
                },
              },
            }
          );
          var _resource = new response();
          _resource.$save(callback);
        },
        getVoteCollectorAssignment: function (gmId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: `v2/gm/general-meetings/${gmId}/vote-collector-assignments`,
            method: 'GET',
          });
        },
        getVCShareclassList: function (gmId, userType, formLanguage) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/votingresults/' +
              gmId +
              '/' +
              $sessionStorage.userId +
              '/' +
              userType +
              '/' +
              formLanguage,
            method: 'GET',
          });
        },
      };
    },
  ]);
})();
