/**
 * @ngdoc service
 * @name generalMeetingMainModule.GeneralMeetingProgressService
 * @description This is a service used for progress bar in general meeting view
 * @requires $sessionStorage to store/pass session data
 * @requires $filter
 */
angular.module('generalMeetingMainModule').service(
        'GeneralMeetingProgressService',
        ['$sessionStorage', '$filter', 'generalMeetingService', function($sessionStorage, $filter, generalMeetingService) {

          this.getGeneralMeetingProgressData = function(currentDate,gmScheduleInfo,flag) {
            var progressData = [];
            var convocationDatePastDue = this.isPastDue(currentDate, gmScheduleInfo.convocationDateMiilis);
            var publicationDatePastDue = this.isPastDue(currentDate, gmScheduleInfo.publicationDateMiilis);
            var intermediaryAccessDatePastDue = this.isPastDue(currentDate, gmScheduleInfo.intermediaryAccessDateMiilis);
            var shareholderAccessDatePastDue = this.isPastDue(currentDate, gmScheduleInfo.shareholderAccessDateMiilis);
            var recordDatePastDue = this.isPastDue(currentDate, gmScheduleInfo.recordDateMiilis);
            var shareholderDeadlineDatePastDue = this.isPastDue(currentDate, gmScheduleInfo.shareholderDeadlineDateMiilis);
            var closingDatePastDue = this.isPastDue(currentDate, gmScheduleInfo.closingDateMiilis);
            var intermediaryDeadlineDatePastDue  = this.isPastDue(currentDate, gmScheduleInfo.intermediaryDeadlineDateMiilis);
            var registrationReleaseDatePastDue = this.isPastDue(currentDate, gmScheduleInfo.registrationReleaseDateMiilis);
            var generalMeetingDatePastDue = this.isPastDue(currentDate, gmScheduleInfo.generalMeetingDateMiilis);
            var votingResultDatePastDue = this.isPastDue(currentDate, gmScheduleInfo.votingResultDateMiilis);

            progressData.push({
              'label': 'label_generalMeetingDate',
              'value': gmScheduleInfo.generalMeetingDateIso,
              'isPastDue': generalMeetingDatePastDue,
              'timeStamp': gmScheduleInfo.generalMeetingDateMiilis
            });
            progressData.push({
              'label': 'label_recordDate',
              'value': gmScheduleInfo.recordDateIso,
              'isPastDue': recordDatePastDue,
              'timeStamp': gmScheduleInfo.recordDateMiilis
            });

            if ($sessionStorage.usertype === 'IN'){
                    progressData.push({
                      'label': 'label_closingDate',
                      'value': gmScheduleInfo.closingDateIso,
                      'isPastDue': closingDatePastDue,
                      'timeStamp': gmScheduleInfo.closingDateMiilis
                    });
                    progressData.push({
                      'label': 'label_intermediaryDeadlineDate',
                      'value': gmScheduleInfo.intermediaryDeadlineDateIso,
                      'isPastDue': intermediaryDeadlineDatePastDue,
                      'timeStamp': gmScheduleInfo.intermediaryDeadlineDateMiilis
                    });
                  } else if ($sessionStorage.usertype === 'SP' || $sessionStorage.usertype === 'SH' || flag === 1){
                    progressData.push({
                      'label': 'label_accessDate',
                      'value': gmScheduleInfo.shareholderAccessDateIso,
                      'isPastDue': shareholderAccessDatePastDue,
                      'timeStamp': gmScheduleInfo.shareholderAccessDateMiilis
                    });
                    progressData.push({
                      'label': 'label_shareholderDeadlineDate',
                      'value': gmScheduleInfo.shareholderDeadlineDateIso,
                      'isPastDue': shareholderDeadlineDatePastDue,
                      'timeStamp': gmScheduleInfo.shareholderDeadlineDateMiilis
                    });
                  } else {
                    progressData.push({
                      'label': 'label_convocationDate',
                      'value': gmScheduleInfo.convocationDateIso,
                      'isPastDue': convocationDatePastDue,
                      'timeStamp': gmScheduleInfo.convocationDateMiilis
                    });
                    progressData.push({
                      'label': 'label_publicationDate',
                      'value': gmScheduleInfo.publicationDateIso,
                      'isPastDue': publicationDatePastDue,
                      'timeStamp': gmScheduleInfo.publicationDateMiilis
                    });
                    progressData.push({
                      'label': 'label_intermediaryAccessDate',
                      'value': gmScheduleInfo.intermediaryAccessDateIso,
                      'isPastDue': intermediaryAccessDatePastDue,
                      'timeStamp': gmScheduleInfo.intermediaryAccessDateMiilis
                    });
                    progressData.push({
                      'label': 'label_shareholderAccessDate',
                      'value': gmScheduleInfo.shareholderAccessDateIso,
                      'isPastDue': shareholderAccessDatePastDue,
                      'timeStamp': gmScheduleInfo.shareholderAccessDateMiilis
                    });
                    progressData.push({
                      'label': 'label_closingDate',
                      'value': gmScheduleInfo.shareholderDeadlineDateIso,
                      'isPastDue': closingDatePastDue,
                      'timeStamp': gmScheduleInfo.closingDateMiilis
                    });
                    progressData.push({
                      'label': 'label_intermediaryDeadlineDate',
                      'value': gmScheduleInfo.intermediaryDeadlineDateIso,
                      'isPastDue': intermediaryDeadlineDatePastDue,
                      'timeStamp': gmScheduleInfo.intermediaryDeadlineDateMiilis
                    });
                    progressData.push({
                      'label': 'label_regListReleaseDate',
                      'value': gmScheduleInfo.registrationReleaseDateIso,
                      'isPastDue': registrationReleaseDatePastDue,
                      'timeStamp': gmScheduleInfo.registrationReleaseDateMiilis
                    });
                    progressData.push({
                      'label': 'label_votingResult',
                      'value': gmScheduleInfo.votingResultDateIso,
                      'isPastDue': votingResultDatePastDue,
                      'timeStamp': gmScheduleInfo.votingResultDateMiilis
                    });

                  }
                  var sortedArray=$filter('orderBy')(progressData,'timeStamp',false);
                  return sortedArray;
          };

          this.getProgressClass = function(isPastDue, index) {
            var data = [];
            if (isPastDue)
              data.push('success-color');
            else
              data.push('warning-color');
            if (index === 0) {
              data.push('colOne');
            }
            if (index === 1) {
              data.push('colTwo');
            }
            if (index === 2) {
              data.push('colThree');
            }
            if (index === 3) {
              data.push('colFour');
            }
            if (index === 4) {
              data.push('colFive');
            }
            if (index === 5) {
              data.push('colSix');
            }
            if (index === 6) {
              data.push('colSeven');
            }
            if (index === 7) {
              data.push('colEight');
            }
            if (index === 8) {
              data.push('colNine');
            }
            if (index === 9) {
              data.push('colTen');
            }

            return data;
          };

          this.isPastDue = function(currentDate, dateValue) {
            return currentDate >= dateValue;
          };

        }]);
