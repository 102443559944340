/**
 * @ngdoc factory
 * @name dwtModule.createEditDwtService
 * @description Create Dwt service for create and edit dwt events
 * @requires $http for POST request
 * @requires $resource for other REST calls.
 */
(function () {
  'use strict';
  angular.module('dwtModule').factory('createEditDwtService', [
    '$http',
    function ($http) {
      return {
        getCreateDwtData: function (userId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/dwtevents/issuer/' + userId,
            method: 'GET',
          });
        },
        getselectedIssuerShareClass: function (userId, issuerId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/dwtevents/shareclass/' + userId + '/' + issuerId,
            method: 'GET',
          });
        },
        createDwt: function (data, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/dwtevents',
            method: 'POST',
            data: data,
          });
        },
        getDwtEventDetails: function (eventId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/dwtevents/' + eventId,
            method: 'GET',
          });
        },
        dwtEventOperations: function (eventId, data, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/dwtevents/' + eventId,
            method: 'PUT',
            data: data,
          });
        },
        deleteDwtEvent: function (eventId, data, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/dwtevents/' + eventId,
            method: 'DELETE',
            data: data,
          });
        },
        getTaxDeclarationNumber: function (data, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/dwtevents/taxdeclarationnumber',
            method: 'POST',
            data: data,
          });
        },
        createNewISIN: function (data, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/dwtshareclass',
            method: 'POST',
            data: data,
          });
        },
        getPEShareHolderDetails: function (issuerId, productCode, userId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/peshareholders/' +
              issuerId +
              '/' +
              productCode +
              '/' +
              userId,
            method: 'GET',
          });
        },
      };
    },
  ]);
})();
