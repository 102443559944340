/**
 * @ngdoc function
 * @name abnamroApp.services:commonService
 * @description # commonService This service helps to get Insight data from the
 *              API layer.
 */
'use strict';
angular.module('shareHolderModule').factory('VaRequestStateService', [
  '$sessionStorage',
  function ($sessionStorage) {
    initSessionStorage();

    let setMeetingData = function (
      gmDateMillis,
      gmTime,
      gmTimezone,
      gmDateTime, // new version of meeting date (serialized from ZonedDatetime)
      meetingId,
      requestStatus,
      virtualReqId
    ) {
      let meetingData = $sessionStorage.vaRegistration.meetingData;
      meetingData.gmDateMillis = gmDateMillis;
      meetingData.meetingTime = gmTime;
      meetingData.timezoneStr = gmTimezone;
      meetingData.meetingDateTime = gmDateTime;
      meetingData.meetingId = meetingId;
      meetingData.requestStatus = requestStatus;
      meetingData.virtualReqId = virtualReqId;
    };

    let getMeetingData = function () {
      return $sessionStorage.vaRegistration.meetingData;
    };

    let setIssuer = function (issuerDetails) {
      let meetingData = $sessionStorage.vaRegistration.meetingData;
      meetingData.issuerDetails = issuerDetails;
    };

    let setFormData = function (key, data) {
      $sessionStorage.vaRegistration.formData[key] = data;
    };

    let getFormData = function (key) {
      return $sessionStorage.vaRegistration.formData[key];
    };

    let getAllFormData = function () {
      return $sessionStorage.vaRegistration.formData;
    };

    let resetData = function () {
      $sessionStorage.vaRegistration = {
        meetingData: {},
        formData: {},
      };
    };

    function initSessionStorage() {
      if ($sessionStorage.vaRegistration == null) {
        $sessionStorage.vaRegistration = {
          meetingData: {},
          formData: {},
        };
      }
    }

    return {
      setMeetingData: setMeetingData,
      getMeetingData: getMeetingData,
      setIssuer: setIssuer,
      setFormData: setFormData,
      getFormData: getFormData,
      getAllFormData: getAllFormData,
      resetData: resetData,
    };
  },
]);
