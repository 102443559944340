/**
 * @ngdoc service
 * @name dwtHomeModule.DWTDataTableService
 * @description This is a service used to configure ColumnNames , displayNames and options names/labels like edit, delete,
 *    notification etc for event tables/views
 */

angular.module('dwtModule').service('DwtDataTableService', [
  function () {
    // Define options across all workstations
    this.optionParams = {};
    this.optionParams['edit'] = {
      label: 'label_edit',
      className: 'glyphicon ocf-icon-large  ocf-icon-pencil_edit iconclass',
      event: 'edit',
    };
    this.optionParams['delete'] = {
      label: 'label_delete',
      className: 'glyphicon ocf-icon-large ocf-icon-delete iconclass',
      event: 'delete',
    };
    this.optionParams['notification'] = {
      label: 'label_notification',
      className: 'glyphicon ocf-icon-large  icon-notification iconclass',
      event: 'notification',
    };
    this.optionParams['inviteSharePlan'] = {
      label: 'label_inviteShareplan',
      className: 'glyphicon ocf-icon-large  icon-inviteShareplan iconclass',
      event: 'share',
    };
    this.optionParams['approveVC'] = {
      label: 'label_accept',
      className: 'glyphicon ocf-icon-large icon-tick-yes iconclass',
      event: 'approveVC',
    };
    this.optionParams['rejectVC'] = {
      label: 'label_decline',
      className: 'glyphicon ocf-icon-large_no ocf-icon-No iconclass',
      event: 'rejectVC',
    };
    this.optionParams['vote'] = {
      label: 'label_vote',
      className: 'glyphicon ocf-icon-large30 icon-vote iconclass',
      event: 'vote',
    };
    this.optionParams['attend'] = {
      label: 'label_attend',
      className: 'glyphicon ocf-icon-large30 icon-attend iconclass',
      event: 'attend',
    };
    this.optionParams['downloadCertificate'] = {
      label: 'Download all documents',
      className: 'glyphicon ocf-icon-large download-arrow-button iconclass',
      event: 'downloadDocument',
    };
    this.optionParams['shareholderEmail'] = {
      label: 'label_shareHolderEmail',
      className: 'glyphicon ocf-icon-large icon-inviteShareplan iconclass',
      event: 'shareholderEmail',
    };
    this.optionParams['createExtensionRequest'] = {
      label: 'label_createExtnReq',
      className: 'glyphicon ocf-icon-large icon-createExtnReq iconclass',
      event: 'createExtensionRequest',
    };
    this.optionParams['createPEClaim'] = {
      label: 'label_PE',
      className: 'actionbtn align-dwt-tbl-btn pe-qrf-btn-width',
      event: 'createPE',
    };
    this.optionParams['createQRFClaim'] = {
      label: 'label_QRF',
      className: 'actionbtn align-dwt-tbl-btn pe-qrf-btn-width',
      event: 'createQRF',
    };
    this.optionParams['bulkUploadQRF'] = {
      label: 'label_QRF',
      className: 'actionbtn align-dwt-tbl-btn pe-qrf-btn-width',
      event: 'bulkUploadQRF',
    };
    this.optionParams['viewExtnReq'] = {
      label: 'label_extReq',
      className: 'ocf-icon-large fa fa-calendar fa-lg iconclass',
      event: 'viewExtnReq',
    };
    this.optionParams['sendEmailToTaxAuthority'] = {
      label: 'sendEmail_taxAuthority',
      className: 'glyphicon ocf-icon-large icon-inviteShareplan iconclass',
      event: 'sendEmailToTaxAuthority',
    };
    this.getColumnDefinationByWorkStationId = function (workStationId) {
      if (workStationId === 'AG') {
        return [
          {
            columnName: 'label_issuer',
            dataIndex: 'issuerName',
            sortValue: 'issuerName',
            className: 'widthP13',
            optionColumn: false,
            url: 'dwtDetails',
            align: 'left',
          },
          {
            columnName: 'label_Security_Name',
            dataIndex: 'shareClassName',
            sortValue: 'shareClassName',
            className: 'widthP13',
            optionColumn: false,
            align: 'left',
          },
          {
            columnName: '',
            dataIndex: 'dividendType',
            sortValue: 'div',
            className: 'widthP9',
            optionColumn: false,
            align: 'right',
          },
          {
            columnName: 'label_isin',
            dataIndex: 'isin',
            sortValue: 'isin',
            className: 'widthP10',
            optionColumn: false,
          },
          {
            columnName: 'label_Payment_date',
            dataIndex: 'paymentDateStr',
            sortValue: 'paymentDate',
            className: 'widthP9',
            optionColumn: false,
          },
          {
            columnName: 'label_QRFEndDate',
            dataIndex: 'qrfEndDateStr',
            sortValue: 'qrfEndDate',
            className: 'widthP12',
            optionColumn: false,
          },
          {
            columnName: 'label_PEEndDate',
            dataIndex: 'peEndDateStr',
            sortValue: 'peEndDate',
            className: 'widthP12',
            optionColumn: false,
          },
          {
            columnName: 'label_options',
            dataIndex: '',
            sortValue: '',
            className: 'widthP15',
            optionColumn: true,
            align: 'left',
          },
        ];
      } else if (workStationId === 'IS') {
        return [
          {
            columnName: 'label_event_name',
            dataIndex: 'eventName',
            sortValue: 'eventName',
            className: 'widthP14',
            optionColumn: false,
            url: 'dwtDetails',
            align: 'left',
          },
          {
            columnName: 'label_Security_Name',
            dataIndex: 'shareClassName',
            sortValue: 'shareClassName',
            className: 'widthP11',
            optionColumn: false,
          },
          {
            columnName: '',
            dataIndex: 'dividendType',
            sortValue: 'div',
            className: 'widthP11',
            optionColumn: false,
            align: 'right',
          },
          {
            columnName: 'label_isin',
            dataIndex: 'isin',
            sortValue: 'isin',
            className: 'widthP11',
            optionColumn: false,
          },
          {
            columnName: 'label_Payment_date',
            dataIndex: 'paymentDateStr',
            sortValue: 'paymentDate',
            className: 'widthP14',
            optionColumn: false,
          },
          {
            columnName: 'label_QRFEndDate',
            dataIndex: 'qrfEndDateStr',
            sortValue: 'qrfEndDate',
            className: 'widthP14',
            optionColumn: false,
          },
          {
            columnName: 'label_PEEndDate',
            dataIndex: 'peEndDateStr',
            sortValue: 'peEndDate',
            className: 'widthP14',
            optionColumn: false,
          },
          {
            columnName: 'label_options',
            dataIndex: '',
            sortValue: '',
            className: 'widthP14',
            optionColumn: true,
            align: 'left',
          },
        ];
      } else if (workStationId === 'IN') {
        return [
          {
            columnName: 'label_issuer',
            dataIndex: 'issuerName',
            sortValue: 'issuerName',
            className: 'widthP11',
            optionColumn: false,
            url: 'dwtDetails',
            align: 'left',
          },
          {
            columnName: 'label_Security_Name',
            dataIndex: 'shareClassName',
            sortValue: 'shareClassName',
            className: 'widthP12',
            optionColumn: false,
          },
          {
            columnName: '',
            dataIndex: 'dividendType',
            sortValue: 'div',
            className: 'widthP12',
            optionColumn: false,
            align: 'right',
          },
          {
            columnName: 'label_isin',
            dataIndex: 'isin',
            sortValue: 'isin',
            className: 'widthP12',
            optionColumn: false,
          },
          {
            columnName: 'label_Payment_date',
            dataIndex: 'paymentDateStr',
            sortValue: 'paymentDate',
            className: 'widthP11',
            optionColumn: false,
          },
          {
            columnName: 'label_QRFEndDate',
            dataIndex: 'qrfEndDateStr',
            sortValue: 'qrfEndDate',
            className: 'widthP14',
            optionColumn: false,
          },
          {
            columnName: 'label_PEEndDate',
            dataIndex: 'peEndDateStr',
            sortValue: 'peEndDate',
            className: 'widthP14',
            optionColumn: false,
          },
          {
            columnName: 'label_options',
            dataIndex: '',
            sortValue: '',
            className: 'widthP14',
            optionColumn: true,
            align: 'left',
          },
        ];
      } else {
        return [];
      }
    };
    this.getFilterButtonsByWorkStationId = function (workStationId) {
      if (workStationId === 'AG') {
        return [
          {
            displayName: 'button0',
            code: 'D',
            showCount: true,
            showOptions: true,
            options: [this.optionParams['edit'], this.optionParams['delete']],
          },
          {
            displayName: 'button1',
            code: 'P',
            showCount: true,
            showOptions: true,
            options: [this.optionParams['edit'], this.optionParams['delete']],
          },
          {
            displayName: 'button2',
            code: 'T',
            showCount: true,
            showOptions: true,
            options: [this.optionParams['edit'], this.optionParams['delete']],
          },
          {
            displayName: 'button3',
            code: 'L',
            showCount: true,
            showOptions: true,
            options: [
              this.optionParams['edit'],
              this.optionParams['notification'],
              this.optionParams['viewExtnReq'],
              this.optionParams['sendEmailToTaxAuthority'],
            ],
          },
          {
            displayName: 'button4',
            code: 'M',
            showCount: false,
            showOptions: true,
            options: [
              this.optionParams['downloadCertificate'],
              this.optionParams['sendEmailToTaxAuthority'],
            ],
          },
          {
            displayName: 'button5',
            code: 'C',
            showCount: false,
            showOptions: false,
          },
        ];
      } else if (workStationId === 'IS') {
        return [
          {
            displayName: 'button1',
            code: 'P',
            showCount: true,
            showOptions: false,
            options: [this.optionParams['edit'], this.optionParams['delete']],
          },
          {
            displayName: 'button2',
            code: 'T',
            showCount: true,
            showOptions: false,
            options: [this.optionParams['edit'], this.optionParams['delete']],
          },
          {
            displayName: 'button3',
            code: 'L',
            showCount: true,
            showOptions: true,
            options: [this.optionParams['viewExtnReq']],
          },
          {
            displayName: 'button4',
            code: 'M',
            showCount: false,
            showOptions: true,
            options: [this.optionParams['downloadCertificate']],
          },
          {
            displayName: 'button5',
            code: 'C',
            showCount: false,
            showOptions: false,
          },
        ];
      } else if (workStationId === 'IN') {
        return [
          {
            displayName: 'button3',
            code: 'L',
            showCount: true,
            showOptions: true,
            options: [
              this.optionParams['createPEClaim'],
              this.optionParams['bulkUploadQRF'],
              this.optionParams['createExtensionRequest'],
              this.optionParams['viewExtnReq'],
            ],
          },
          {
            displayName: 'button4',
            code: 'M',
            showCount: false,
            showOptions: false,
          },
        ];
      } else {
        return [];
      }
    };
  },
]);
