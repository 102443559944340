'use strict';
/**
 * @ngdoc factory
 * @name commonModule.TokenSvc
 * @description This is a factory used for common module related REST calls
 * @requires $http for POST request
 * @requires $q for the defer
 * @requires $log for debug logs
 */
angular.module('commonModule').factory(
        'TokenSvc',['$http', '$q', '$log',
        function($http, $q, $log) {

          /**
           * Wrapper method for doing service calls
           * @param url
           * @param method
           * @param params
           * @param data
           * @returns {promise|*|null|Promise|d}
           */
          function doServiceCall(url, method, params, data) {
            var defer = $q.defer();
            $http({
              url: url,
              method: method,
              params: params,
              data: data
            }).then(function(resp) {
              defer.resolve(resp);
              $log.debug(resp);
            }, function(err) {
              $log.debug(err);
              defer.reject(err);
            });
            return defer.promise;
          }
          /**
           * Submits a request to API for password reset in case of user forgets
           * his password This will trigger a mail with instructions to reset
           * password
           * @param userId
           * @param userType
           * @returns {*}
           */
          function passwordResetRequest(userId, userType) {
            return doServiceCall('v1/password/forget', 'POST', null, {
              username: userId,
              userType: userType
            });
          }


          /**
           * Checks if the link received in the mail is valid for the password
           * reset process
           * @param token
           * @param userType
           * @returns {*}
           */
          function checkPasswordResetUrl(token) {
              return doServiceCall('v1/login/authenticateToken/' + token, 'POST',
                      null, {
                        token: token
                      });
            }
          
          /**
           * Checks if the link received in the mail is valid for the password
           * reset process
           * @param token
           * @param userType
           * @returns {*}
           */
          function getPasswordResetFlag(token) {
            return doServiceCall('v1/login/authenticateToken/sp/' + token, 'GET',
                    null, {});
          }

          /**
           * Resets the password for the user who initiated the password reset
           * process
           * @param requestData
           * @returns {*}
           */
          function resetNewPassword(requestData) {
            return doServiceCall('v1/password/confirm', 'POST', null,
                    requestData);
          }

          return {
            passwordResetRequest: passwordResetRequest,
            checkPasswordResetUrl: checkPasswordResetUrl,
            getPasswordResetFlag: getPasswordResetFlag,
            resetNewPassword: resetNewPassword, 
          };
        }]);
