(function () {
  'use strict';

  /**
   * @ngdoc factory
   * @name : PaginationSvc
   * @returns : object with all pager properties required by the view
   * @requires : totalItems, currentPage, pageSize
   * @description : Common pagination service for all the modules.
   */

  angular.module('commonModule').factory('PaginationSvc', function () {

    var service = {};
    var totalPages = 0;
    var startPage, endPage;
    var startIndex = 0;
    var endIndex = 0;
    var pages = [];
    var i = 0;

    service.GetPager = GetPager;

    return service;

    /**
     * Wrapper method to get the pager based on the input values
     *
     * @ngdoc method
     * @param totalItems
     * @param currentPage
     * @param pageSize
     * @returns return object with all pager properties required by the view
     * @description : Common pagination service for all the modules.
     */

    function GetPager(totalItems, currentPage, pageSize) {
      // default to first page
      currentPage = currentPage || 1;

      // default page size is 10
      pageSize = pageSize || 10;

      // calculate total pages
      totalPages = Math.ceil(totalItems / pageSize);
      if (currentPage > totalPages) {
        currentPage = totalPages;
      }
      if (totalPages <= 10) {
        // less than 10 total pages so show all
        startPage = 1;
        endPage = totalPages;
      } else {
        // more than 10 total pages so calculate start and end pages
        if (currentPage <= 6) {
          startPage = 1;
          endPage = 10;
        } else if (currentPage + 4 >= totalPages) {
          startPage = totalPages - 9;
          endPage = totalPages;
        } else {
          startPage = currentPage - 5;
          endPage = currentPage + 4;
        }
      }

      // calculate start and end item indexes
      startIndex = (currentPage - 1) * pageSize;
      endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

      // create an array of pages to ng-repeat in the pager control
      pages = [];
      for (i = startPage - 1; i < endPage; i++) {
        pages.push(i + 1);
      }

      // return object with all pager properties required by the view
      return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
      };
    }
  });

  angular
  .module('commonModule')
  .directive('pagination', function () {
    return {
      restrict: 'E',
      templateUrl: 'shared/directives/templates/pagination-template.html',
      transclude: true
    };
  });

  angular
  .module('commonModule')
  .directive('paginationDwt', function () {
        return {
          restrict: 'E',
          templateUrl: 'shared/directives/templates/dwt-pagination-template.html',
          transclude: true
        };
      });

})();
