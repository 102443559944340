(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name : view dwt extn service
   * @returns : rest calls to view dwt extn, creating JSON request objects for service calls.
   * @description : Service to view dwt extn.
   */
  angular
    .module('dwtApproveRejectExtensionModule')
    .factory('ViewDwtExtensionReqService', [
      '$http',
      '$localStorage',
      '$sessionStorage',
      '$resource',
      function ($http, $localStorage, $sessionStorage, $resource) {
        return {
          getDwtExtensionRequests: function (pageNo) {
            return $resource(
              'v1/extensionrequest/' +
                $sessionStorage.groupId +
                '/' +
                $sessionStorage.usertype +
                '/' +
                pageNo +
                '/500/DWT',
              {},
              {
                query: {
                  method: 'GET',
                  isArray: true,
                },
              }
            );
          },
          getDwtSingleExtensionRequests: function (
            userType,
            pageNo,
            userGroupId,
            eventId
          ) {
            return $http({
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              url:
                'v1/extensionrequest/' +
                userType +
                '/' +
                pageNo +
                '/500/' +
                userGroupId +
                '/' +
                eventId +
                '/view',
              method: 'GET',
            });
          },
          dwtApproveReject: function (data) {
            return $http({
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              url: 'v1/dwt/extension-requests/approve-or-reject',
              method: 'PUT',
              data: data,
            });
          },
        };
      },
    ]);
})();
