(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name : DWT Audit trail service
   * @returns : service to fetch data from rest layer for DWT Audit trail service.
   * @description : Service to fetch data from rest layer for DWT Audit trail service.
   */

  angular.module('dwtModule').factory('DWTAuditTrailService', [
    '$http',
    '$resource',
    '$log',
    function ($http, $resource, $log) {
      return {
        getActionDropDown: function () {
          return $resource(
            'v1/audittrails/data/DWT',
            {},
            {
              post: {
                method: 'POST',
              },
            }
          );
        },
        getAllAuditTrailsData: function (pageFrom) {
          return $resource(
            'v1/audittrails/DWT/' + pageFrom + '/100',
            {},
            {
              post: {
                method: 'POST',
              },
            }
          );
        },
        getAllEmailAuditTrailsData: function (pageFrom) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/emailaudittrails/DWT/' + pageFrom + '/100',
            method: 'GET',
          });
        },
        searchAuditTrail: function (data, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/audittrails',
            method: 'POST',
            data: data,
          });
        },
        searchEmailAudit: function (data, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/emailaudittrails',
            method: 'POST',
            data: data,
          });
        },
        getTemplateTypes: function () {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/templates/type',
            method: 'GET',
          });
        },
        getSubjectOptions: function () {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/templates/certificatedata',
            method: 'GET',
          });
        },
        getEmailSubjectOptions: function () {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/templates/DWT/emaildata',
            method: 'GET',
          });
        },
        getWebSubjectOptions: function () {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/templates/frontendtext',
            method: 'GET',
          });
        },
        getIssuerList: function () {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/templates/issuerList',
            method: 'GET',
          });
        },
        searchTemplate: function (data, authtoken) {
          $log.debug(data);
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/templates/search',
            method: 'POST',
            data: data,
          });
        },
        updateTemplate: function (data, authtoken, showIssuerFlag) {
          var url;
          var method;
          if (showIssuerFlag === false) {
            url = 'v1/templates/edit';
            method = 'POST';
          } else {
            url = 'v1/templates';
            method = 'PUT';
          }
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: url,
            method: method,
            data: data,
          });
        },
        deleteTemplate: function (templateType, templateId, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/templates/' + templateType + '/' + templateId + '',
            method: 'DELETE',
          });
        },
        getAuditTrailDropdown: function () {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/audittrails/dropDownData',
            method: 'GET',
          });
        },
        getEventByIssuerId: function (issuerId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/audittrails/' + issuerId,
            method: 'GET',
          });
        },
        resendEmail: function (emailSentId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/emailaudittrails/' + emailSentId + '/resend',
            method: 'POST',
          });
        },
        filterEmailAuditTrail: function (data) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/emailaudittrails',
            method: 'POST',
            data: data,
          });
        },
      };
    },
  ]);
})();
