/**
 * @ngdoc service
 * @name commonModule.LanguagePreferenceService
 * @description This is a service used for dealing with page navigation history
 * @requires $filter
 */
angular.module('commonModule').service('LanguagePreferenceService', [

function() {
  this.receivers = [];
  this.register = function(receiver) {
    this.receivers.push(receiver);
  };

  this.invoke = function(lang) {
    angular.forEach(this.receivers, function(receiver) {
      receiver.invoke(lang);
    });
  };

}]);
