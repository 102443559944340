/**
 * @ngdoc factory
 * @name captchamodule.captchaService
 * @description This is a factory used captcha related REST calls
 * @requires $http for POST request
 * @requires $resource for other REST calls.
 */
(function() {
  'use strict';
  angular
          .module('captchamodule')
          .service('captchaService', function() {
            this.setResponseToken = function(response) {
              this.responseToken = response;
            };
            this.getResponseToken = function() {
              return this.responseToken;
            };
            this.setErrorCode = function(errorCode) {
              this.errorCode = errorCode;
            };
            this.getErrorCode = function() {
              return this.errorCode;
            };

          });
})();
