/**
 * @ngdoc factory
 * @name intermediaryModule.intermediaryService
 * @description This is a factory used for intermediary work station related REST calls
 * @requires $http for POST request
 * @requires $resource for the REST calls
 * @requires $sessionStorage to store/pass session data
 * @requires $log to handle debug logs
 */
(function () {
  'use strict';

  angular.module('intermediaryModule').factory('intermediaryService', [
    '$http',
    '$sessionStorage',
    '$resource',
    '$log',
    function ($http, $sessionStorage, $resource) {
      return {
        getIntermediaryGeneralMeetings: function (
          pageNumber,
          pageSize,
          event,
          sortDirection,
          sortBy,
          searchParam
        ) {
          return $resource(
            'v1/portalusers/intermediary/' +
              'generalMeetings?pageNumber=' +
              pageNumber +
              '&pageSize=' +
              pageSize +
              '&status=' +
              event +
              '&sortDirection=' +
              sortDirection +
              '&sortBy=' +
              sortBy +
              '&filter=' +
              searchParam,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },

        getIntermediaryLinkedMeetings: function (meetingId, shareclassId) {
          return $resource(
            'v1/general-meetings/' +
              meetingId +
              '/shareclasses/' +
              shareclassId +
              '/linked-meetings',
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
      };
    },
  ]);
})();
