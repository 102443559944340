import {
  getEmptyGmScheduleVO
} from '@/shared/utilities/general-meeting-utilities';

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name : gmScheduleFormDataService
   * @description : Service function for creating Schedule form data
   */
  angular.module('commonModule').factory('gmScheduleFormDataService', [
    '$filter', 'dateFormatterService',
    function ($filter, dateFormatterService) {
      return {
        // Service function for creating general meeting schedule data
        createGMScheduleData: function (gm, gmScheduleVO, timezone) {
          if (timezone === null || timezone === undefined || timezone === '') {
            gm.createData.gmScheduleVO = null;
          } else {
            gm.createData.gmScheduleVO = getEmptyGmScheduleVO();
            gm.createData.gmScheduleVO.timezone = timezone;
            gm.createData.gmScheduleVO.convocationDate = dateFormatterService.dateToStringConverter(
                gmScheduleVO.convocationDate
            );
            gm.createData.gmScheduleVO.intermediaryAccessDate = dateFormatterService.dateToStringConverter(
                gmScheduleVO.intermediaryAccessDate
            );
            gm.createData.gmScheduleVO.shareholderDeadlineDate = dateFormatterService.dateToStringConverter(
                gmScheduleVO.shareholderDeadlineDate
            );
            gm.createData.gmScheduleVO.publicationDate = dateFormatterService.dateToStringConverter(
                gmScheduleVO.publicationDate
            );
            gm.createData.gmScheduleVO.recordDate = dateFormatterService.dateToStringConverter(
                gmScheduleVO.recordDate
            );
            gm.createData.gmScheduleVO.intermediaryDeadlineDate = dateFormatterService.dateToStringConverter(
                gmScheduleVO.intermediaryDeadlineDate
            );
            gm.createData.gmScheduleVO.registrationReleaseDate = dateFormatterService.dateToStringConverter(
                gmScheduleVO.registrationReleaseDate
            );
            gm.createData.gmScheduleVO.shareholderAccessDate = dateFormatterService.dateToStringConverter(
                gmScheduleVO.shareholderAccessDate
            );
            gm.createData.gmScheduleVO.generalMeetingDate = dateFormatterService.dateToStringConverter(
                gmScheduleVO.generalMeetingDate
            );
          }
          return gm.createData.gmScheduleVO;
        }
      };
    },
  ]);
})();


