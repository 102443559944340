import { MenuItem, GM_LABELS, ACCOUNT_LABELS } from './_common';

export function getShareplanMenuItems(
  lang = 'EN',
  productTypes,
  { gmId, issuerId }
) {
  const labels = {
    gm: GM_LABELS,
    account: ACCOUNT_LABELS,
    votes: {
      EN: 'My Votes / Registrations',
      NL: 'Mijn uitgebrachte stemmen',
      FR: 'Mes votes',
      DE: 'Meine Stimmen',
      ES: 'Mis inscripciones/votos emitidos'
    },
  };

  const url = gmId && issuerId ? 'generalMeetings' : 'gmList';

  return [
    new MenuItem(labels.gm[lang], url, 0),
    new MenuItem(labels.votes[lang], 'shMyVotes', 1),
    new MenuItem(labels.account[lang], 'myAccounts', 2),
  ];
}
