/**
 * @ngdoc service
 * @name generalMeetingMainModule.GeneralMeetingDataLanguageService
 * @description This is a service used for setting values for AgendaDetails, DocumentDetails, ShareClassDetails,
 *                 TypeAndLocationDetails based on language in general meeting view
 * @requires $filter
 */

angular.module('generalMeetingMainModule').service('GeneralMeetingDataLanguageService',['$filter',function($filter){

    this.formLanguage = 'EN';
    this.getAgendaDetails = function(agendaVoList){
        var selectedLang =  $filter('uppercase')(this.formLanguage);
        angular.forEach(agendaVoList,function(agenda){

            var filteredArray = $filter('filter')(agenda.agendaDetailVOList,{'languageCode':selectedLang});
            if(!filteredArray || filteredArray.length ===0 )
            {
                filteredArray = [];
                filteredArray.push({languageCode: selectedLang, agendaExplanation: '',agendaTitle: '', file: null});
            }
            agenda.agendaDetailVOList = filteredArray;
        });
        return agendaVoList;
    };

    this.getAgendaDetailsCreateGM = function(agendaVoList){
        var selectedLang =  $filter('uppercase')(this.formLanguage);
        angular.forEach(agendaVoList,function(agenda){
            var filteredArray = $filter('filter')(agenda.agendaDetailVOList,{'languageCode':selectedLang});
            if(!filteredArray || filteredArray.length ===0 )
            {
                agenda.agendaDetailVOList.push({languageCode: selectedLang, agendaExplanation: '',agendaTitle: '', file: null});
            }
            var filteredArraySorted = $filter('orderBy')(agenda.agendaDetailVOList, function(records) {
                return records.languageCode !== selectedLang;
            });
            agenda.agendaDetailVOList = filteredArraySorted;
        });
        return agendaVoList;
    };

    this.getAgendaDetailsShareHolder = function(agendaVoList){
        var selectedLang =  $filter('uppercase')(this.formLanguage);
        angular.forEach(agendaVoList,function(agenda){

            var filteredArray = $filter('filter')(agenda.agendaDetailVOList,{'languageCode':selectedLang});
            if(!filteredArray || filteredArray.length ===0 )
            {
                filteredArray = $filter('filter')(agenda.agendaDetailVOList,{'languageCode':'EN'});
            }
            agenda.agendaDetailVOList = filteredArray;

        });
        return agendaVoList;

    };

    this.getDocumentDetails = function(additionalDocumentList){
        var selectedLang =  $filter('uppercase')(this.formLanguage);
        angular.forEach(additionalDocumentList,function(document){

            var filteredArray = $filter('filter')(document.meetingDocAttachList,{'languageCode':selectedLang});
            if(!filteredArray || filteredArray.length ===0 )
            {
                filteredArray = [];
                filteredArray.push({attachedFile :null,file:null,fileName:null,langCode:selectedLang});
            }
            document.meetingDocAttachList = filteredArray;
        });
        return additionalDocumentList;
    };
    this.getDocumentDetailsCreateGM = function(additionalDocumentList){
        var selectedLang =  $filter('uppercase')(this.formLanguage);
        angular.forEach(additionalDocumentList,function(document){
            var filteredArray = $filter('filter')(document.meetingDocAttachList,{'langCode':selectedLang});
            if(!filteredArray || filteredArray.length ===0 )
            {
                document.meetingDocAttachList.push({attachedFile :null,file:null,fileName:null,langCode:selectedLang});
            }
            var filteredArraySorted = $filter('orderBy')(document.meetingDocAttachList, function(records) {
                return records.langCode !== selectedLang;
            });
            document.meetingDocAttachList = filteredArraySorted;

        });
        return additionalDocumentList;
    };

    this.getDocumentDetailsCreateGM = function(additionalDocumentList){
        var selectedLang =  $filter('uppercase')(this.formLanguage);
        angular.forEach(additionalDocumentList,function(document){

            var filteredArray = $filter('filter')(document.meetingDocAttachList,{'langCode':selectedLang});
            if(filteredArray && (!filteredArray || filteredArray.length ===0 ))
            {
                document.meetingDocAttachList.push({attachedFile :null,file:null,fileName:null,langCode:selectedLang});
            }
            var filteredArraySorted = $filter('orderBy')(document.meetingDocAttachList, function(records) {
                return records.langCode !== selectedLang;
            });
            document.meetingDocAttachList = filteredArraySorted;

        });
        return additionalDocumentList;
    };

    this.getDocumentDetailsShareHolder = function(additionalDocumentList){
        var selectedLang =  $filter('uppercase')(this.formLanguage);
        angular.forEach(additionalDocumentList,function(document){

            var filteredArray = $filter('filter')(document.meetingDocAttachList,{'languageCode':selectedLang});
            if(!filteredArray || filteredArray.length ===0 )
            {
                filteredArray = $filter('filter')(document.meetingDocAttachList,{'languageCode':'EN'});
            }
            document.meetingDocAttachList = filteredArray;

        });
        return additionalDocumentList;
    };

    this.getShareClassDetails = function(shareClassList){
        var selectedLang =  $filter('uppercase')(this.formLanguage);
        angular.forEach(shareClassList,function(shareClass){
            shareClass.isAuthorizedVcSelected = false;
            var filteredArray = $filter('filter')(shareClass.shareClassNameVoList,{'languageCode':selectedLang});

            if(filteredArray && filteredArray.length===0){
                filteredArray = [];
                filteredArray.push({'languageCode':selectedLang,'shareClassName':null});
            }
            shareClass.shareClassNameVoList = filteredArray;
            angular.forEach(shareClass.authorizedVoteCollectorList,function(item){
                if(!item.isDeleted && (item.isIssuer || (!item.isAgent && ! item.isIssuer))){
                    shareClass.isAuthorizedVcSelected = true;
                }
                var shareClassArray = $filter('filter')(item.shareclassNames,{'languageCode':selectedLang});
                if(shareClassArray && shareClassArray.length>0){
                    item.scName = shareClassArray[0].shareClassName;
                }
                var voteCollectorDisplayNames = $filter('filter')(item.voteCollectorDisplayNames,{'languageCode':selectedLang});
                if(voteCollectorDisplayNames && voteCollectorDisplayNames.length>0){
                    item.vcName = voteCollectorDisplayNames[0].vcDispalyName;
                }else{
                // if display name is not available in other language, use english display name.
                    voteCollectorDisplayNames = $filter('filter')(item.voteCollectorDisplayNames,{'languageCode':'EN'});
                    if(voteCollectorDisplayNames && voteCollectorDisplayNames.length>0){
                        item.vcName = voteCollectorDisplayNames[0].vcDispalyName;
                    }
                }
            });
        });
        return shareClassList;
    };

    this.getShareClassDetailsCreateGM = function(shareClassList){
        var selectedLang =  $filter('uppercase')(this.formLanguage);
        angular.forEach(shareClassList,function(shareClass){
            shareClass.isAuthorizedVcSelected = false;
            var filteredArray = $filter('filter')(shareClass.shareClassNameVoList,{'languageCode':selectedLang});
            if(filteredArray && filteredArray.length===0){
                shareClass.shareClassNameVoList.push({'languageCode':selectedLang,'shareClassName':null});
            }
            var filteredArraySorted = $filter('orderBy')(shareClass.shareClassNameVoList, function(records) {
                return records.languageCode !== selectedLang;
            });
            shareClass.shareClassNameVoList = filteredArraySorted;
            angular.forEach(shareClass.authorizedVoteCollectorList,function(item){
                if(!item.isDeleted && (item.isIssuer || (!item.isAgent && ! item.isIssuer))){
                    shareClass.isAuthorizedVcSelected = true;
                }
                var shareClassArray = $filter('filter')(item.shareclassNames,{'languageCode':selectedLang});
                if(shareClassArray && shareClassArray.length>0){
                    item.scName = shareClassArray[0].shareClassName;
                }
            });
            shareClass.shareClassName = shareClass.shareClassNameVoList[0].shareClassName;
        });
        return shareClassList;
    };

    this.getShareClassVOListCreateGM = function(shareClassVOList) {
        let selectedLang =  $filter('uppercase')(this.formLanguage);
        angular.forEach(shareClassVOList,function(shareClass){
            let filteredArray = $filter('filter')(shareClass.shareClassNameVoList,{'languageCode':selectedLang});
            if (filteredArray && filteredArray.length===0){
                shareClass.shareClassNameVoList.push({'languageCode':selectedLang,'shareClassName':null});
            }
            let filteredArraySorted = $filter('orderBy')(shareClass.shareClassNameVoList, function(records) {
                return records.languageCode !== selectedLang;
            });
            shareClass.shareClassNameVoList = filteredArraySorted;
        });
        return shareClassVOList;
    };

    this.getShareClassDetailsShareHolder = function(shareClassList){
        var selectedLang =  $filter('uppercase')(this.formLanguage);
        angular.forEach(shareClassList,function(shareClass){
            shareClass.isAuthorizedVcSelected = false;
            var filteredArray = $filter('filter')(shareClass.shareClassNameVoList,{'languageCode':selectedLang});

            if(filteredArray && filteredArray.length===0){
                filteredArray = $filter('filter')(shareClass.shareClassNameVoList,{'languageCode':'EN'});
            }
            shareClass.shareClassNameVoList = filteredArray;
            angular.forEach(shareClass.authorizedVoteCollectorList,function(item){
                if(!item.isDeleted && (item.isIssuer || (!item.isAgent && ! item.isIssuer))){
                    shareClass.isAuthorizedVcSelected = true;
                }
                var shareClassArray = $filter('filter')(item.shareclassNames,{'languageCode':selectedLang});
                if(shareClassArray && shareClassArray.length>0){
                    item.scName = shareClassArray[0].shareClassName;
                }
                if(shareClassArray && shareClassArray.length===0){
                    shareClassArray = $filter('filter')(item.shareclassNames,{'languageCode':'EN'});
                    item.scName = shareClassArray[0].shareClassName;
                }
                var voteCollectorDisplayNames = $filter('filter')(item.voteCollectorDisplayNames,{'languageCode':selectedLang});
                if(voteCollectorDisplayNames && voteCollectorDisplayNames.length>0){
                    item.vcName = voteCollectorDisplayNames[0].vcDispalyName;
                }else{
                // if display name is not available in other language, use english display name.
                    voteCollectorDisplayNames = $filter('filter')(item.voteCollectorDisplayNames,{'languageCode':'EN'});
                    if(voteCollectorDisplayNames && voteCollectorDisplayNames.length>0){
                        item.vcName = voteCollectorDisplayNames[0].vcDispalyName;
                    }
                }

            });
        });
        return shareClassList;
    };


    this.getGetTypeAndLocationDetails = function(typeAndLocationVOList){
        var selectedLang =  $filter('uppercase')(this.formLanguage);
        var filteredArray = $filter('filter')(typeAndLocationVOList,{'languageCode':selectedLang});
        if(!filteredArray || filteredArray.length ===0 )
        {
            filteredArray = [];
            filteredArray.push({languageCode: null, locationName: null, locationTicket: null, street: null, city: null, country: null, meetinName: null, meetingTime: null});
        }
        typeAndLocationVOList = filteredArray;
        return typeAndLocationVOList;
    };
    this.getGetTypeAndLocationDetailsCreateGM = function(typeAndLocationVOList){
        var selectedLang =  $filter('uppercase')(this.formLanguage);
        var filteredArray = $filter('filter')(typeAndLocationVOList,{'languageCode':selectedLang});
        if(!filteredArray || filteredArray.length ===0 )
        {
            typeAndLocationVOList.push({languageCode: selectedLang, locationName: null, locationTicket: null, street: null, city: null, country: null, meetinName: null, meetingTime: null});
        }
        var filteredArraySorted = $filter('orderBy')(typeAndLocationVOList, function(records) {
            return records.languageCode !== selectedLang;
        });
        typeAndLocationVOList = filteredArraySorted;
        return typeAndLocationVOList;
    };

    this.getGetTypeAndLocationDetailsCreateGM = function(typeAndLocationVOList){
        var selectedLang =  $filter('uppercase')(this.formLanguage);
        var filteredArray = $filter('filter')(typeAndLocationVOList,{'languageCode':selectedLang});
        if(!filteredArray || filteredArray.length ===0 )
        {
            typeAndLocationVOList.push({languageCode: selectedLang, locationName: null, locationTicket: null, street: null, city: null, country: null, meetinName: null, meetingTime: null});
        }
        var filteredArraySorted = $filter('orderBy')(typeAndLocationVOList, function(records) {
            return records.languageCode !== selectedLang;
        });
        typeAndLocationVOList = filteredArraySorted;
        return typeAndLocationVOList;
    };

    this.getGetTypeAndLocationDetailsShareHolder = function(typeAndLocationVOList){
        var selectedLang =  $filter('uppercase')(this.formLanguage);
        var filteredArray = $filter('filter')(typeAndLocationVOList,{'languageCode':selectedLang});
        if(!filteredArray || filteredArray.length ===0 )
        {
            filteredArray = $filter('filter')(typeAndLocationVOList,{'languageCode':'EN'});
        }
        typeAndLocationVOList = filteredArray;
        return typeAndLocationVOList;
    };

    this.setLanguage = function(lang){
        this.formLanguage = $filter('uppercase')(lang);
    };

    //SFCBP-1578 - Fix start
    this.getLangSpecificCountries = function(countriesData, lang){
        var filteredArray = $filter('filter')(countriesData,{'languageCode':lang});
        if(!filteredArray || filteredArray.length ===0 )
        {
            filteredArray = $filter('filter')(countriesData,{'languageCode':'EN'});
        }
        countriesData = filteredArray;
        return countriesData;
    };
    //SFCBP-1578 - Fix end

    this.getLangSpecificDates = function(gmDateData, lang){
        angular.forEach(gmDateData,function(gmDateDataOne){
            var filteredArray = $filter('filter')(gmDateDataOne.gmDatesBasedOnLanguage,{'languageCode':lang});
            if(!filteredArray || filteredArray.length ===0 )
            {
                filteredArray = $filter('filter')(gmDateDataOne.gmDatesBasedOnLanguage,{'languageCode':'EN'});
            }
            gmDateDataOne.generalMeetingDateStr = filteredArray[0].generalMeetingDateLang;
            //for GM name changing on language change in myVotes page starts here.
            var filteredArrayGM = $filter('filter')(gmDateDataOne.meetingNameVO,{'languageCode':lang});
            if(!filteredArrayGM || filteredArrayGM.length ===0 )
            {
                filteredArrayGM = $filter('filter')(gmDateDataOne.meetingNameVO,{'languageCode':'EN'});
            }
            if(filteredArrayGM){
                gmDateDataOne.meetingName = filteredArrayGM[0].meetingName;
            }
          //for GM name changing on language change in myVotes page ends here.
        });
        return gmDateData;
    };

    this.getLanguageSpecificData = function(gmData,lang){
        this.setLanguage(lang);
        gmData.gmDetailVOList = this.getGetTypeAndLocationDetails(gmData.gmDetailVOList);
        gmData.gmAgendaVO = this.getAgendaDetails(gmData.gmAgendaVO);
        gmData.gmShareClassVO = this.getShareClassDetails(gmData.gmShareClassVO);
        gmData.gmDocumentVO = this.getDocumentDetails(gmData.gmDocumentVO);
        return gmData;
    };

    this.getLanguageSpecificDataShareHolder = function(gmData,lang){
        this.setLanguage(lang);
        gmData.gmDetailVOList = this.getGetTypeAndLocationDetailsShareHolder(gmData.gmDetailVOList);
        gmData.gmAgendaVO = this.getAgendaDetailsShareHolder(gmData.gmAgendaVO);
        gmData.gmShareClassVO = this.getShareClassDetailsShareHolder(gmData.gmShareClassVO);
        gmData.gmDocumentVO = this.getDocumentDetailsShareHolder(gmData.gmDocumentVO);
        return gmData;
    };

    this.getSupportedLangTpeFlag = function(supportedLangList, lang){
        var filteredArray = $filter('filter')(supportedLangList,{'languageCode':lang});
        if(!filteredArray || filteredArray.length ===0 )
        {
            return false;
        } else{
            return filteredArray[0].isChecked;
        }
    };


}]);
