import {getGmFeaturesVO} from '@/shared/utilities/general-meeting-utilities';

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name : gmFeaturesFormDataService
   * @description : Service function for creating features form data
   */
  angular.module('commonModule').factory('gmFeaturesFormDataService', [
    '$filter',
    function ($filter) {
      return {
        createGMFeatureData: function (gm) {
          gm.createData.gmFeaturesVO = getGmFeaturesVO();
          gm.createData.gmFeaturesVO.splitVotingIntermdWrkst =
              gm.splitVotingIntermdWrkst;
          gm.createData.gmFeaturesVO.rightToSpeak = gm.rightToSpeak;
          gm.createData.gmFeaturesVO.sendRegViEuroclear = gm.sendRegViEuroclear;
          gm.createData.gmFeaturesVO.shWrkstSecAccDiscReq =
              gm.shWrkstSecAccDiscReq;
          //Below flag and meetingid are added for lumi enhancement - SFCBP-3007
          gm.createData.gmFeaturesVO.virtualMeetingAllowed =
              gm.virtualMeetingAllowed;
          gm.createData.gmFeaturesVO.virtualParticipationProviderId =
              gm.selectedVirtualMeetingProvider;
          if (!gm.virtualMeetingId) {
            gm.createData.gmFeaturesVO.virtualMeetingId = null;
          } else {
            gm.createData.gmFeaturesVO.virtualMeetingId = gm.virtualMeetingId;
          }

          var virtualDateTime = null;
          if (gm.virtualMeetingDate !== null) {
            virtualDateTime = new Date(gm.virtualMeetingDate);
            gm.createData.gmFeaturesVO.virtualParticipationJoinDatetime = $filter(
                'date'
            )(virtualDateTime, 'yyyy-MM-dd HH:mm:ss');
          }
          //Below flag is added for linked shareclass meeting changes - SFCBP-2725
          gm.createData.gmFeaturesVO.shWrkstLinkMeeting = gm.shWrkstLinkMeeting;
          gm.createData.gmFeaturesVO.regTickBarcode = gm.regTickBarcode;
          gm.createData.gmFeaturesVO.regTickAgentLogo = gm.regTickAgentLogo;
          gm.createData.gmFeaturesVO.regTickIssuerLogo = gm.regTickIssuerLogo;
          gm.createData.gmFeaturesVO.regTickIncludePOA = gm.regTickIncludePOA;
          gm.createData.gmFeaturesVO.regTickIncludeMeetingDoc =
              gm.regTickIncludeMeetingDoc;
          return gm.createData.gmFeaturesVO;
        }
      };
    },
  ]);
})();

