import appConstant from '@/global/constants';

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name : gmDocumentFormDataService
   * @description : Service function for creating general meeting document data
   */
  angular.module('commonModule').factory('gmDocumentFormDataService', [
    '$filter',
    function ($filter) {
      return {
        createGMDocumentsData: function (
            userType,
            loggedInUserID,
            formLanguage,
            generalMeetingId,
            gm,
            selectedEnglish,
            selectedDutch,
            selectedFrench,
            selectedGerman,
            selectedSpanish
        ) {
          var documentFormData = new FormData();
          documentFormData.append('loggedInUserID', loggedInUserID);
          documentFormData.append('workstation', userType);
          documentFormData.append('languageCode', formLanguage);
          documentFormData.append('generalMeetingId', generalMeetingId);
          documentFormData.append('status', gm.createData.status);
          // supported language
          documentFormData.append('supportedLanguages[0].index', '1');
          documentFormData.append(
              'supportedLanguages[0].isChecked',
              selectedEnglish
          );
          documentFormData.append(
              'supportedLanguages[0].languageCode',
              appConstant.OPTED_EN
          );
          documentFormData.append('supportedLanguages[1].index', '2');
          documentFormData.append(
              'supportedLanguages[1].isChecked',
              selectedDutch
          );
          documentFormData.append(
              'supportedLanguages[1].languageCode',
              appConstant.OPTED_NL
          );
          documentFormData.append('supportedLanguages[2].index', '3');
          documentFormData.append(
              'supportedLanguages[2].isChecked',
              selectedFrench
          );
          documentFormData.append(
              'supportedLanguages[2].languageCode',
              appConstant.OPTED_FR
          );
          documentFormData.append('supportedLanguages[3].index', '4');
          documentFormData.append(
              'supportedLanguages[3].isChecked',
              selectedGerman
          );
          documentFormData.append(
              'supportedLanguages[3].languageCode',
              appConstant.OPTED_DE
          );
          documentFormData.append('supportedLanguages[4].index', '5');
          documentFormData.append(
              'supportedLanguages[4].isChecked',
              selectedSpanish
          );
          documentFormData.append(
              'supportedLanguages[4].languageCode',
              appConstant.OPTED_ES
          );
          if (
              gm.DynamicAdditionalDocuments !== null &&
              gm.DynamicAdditionalDocuments.length > 0
          ) {
            var indexDoc = 0;
            angular.forEach(gm.DynamicAdditionalDocuments, function (
                value,
                key
            ) {
              indexDoc++;
              if (value.meetingDocId !== null) {
                documentFormData.append(
                    'gmDocumentVO[' + key + '].meetingDocId',
                    value.meetingDocId
                );
              }

              documentFormData.append(
                  'gmDocumentVO[' + key + '].attachGmNotice',
                  value.attachGmNotice
              );
              documentFormData.append(
                  'gmDocumentVO[' + key + '].attachGmWebPage',
                  value.attachGmWebPage
              );
              documentFormData.append(
                  'gmDocumentVO[' + key + '].attachCard',
                  value.attachCard
              );
              var docKey = 0;
              documentFormData.append(
                  'gmDocumentVO[' + key + '].index',
                  indexDoc
              );
              var tempDocArray = null;
              if (selectedEnglish) {
                tempDocArray = $filter('filter')(value.meetingDocAttachList, {
                  langCode: 'EN',
                });
                if (tempDocArray && tempDocArray.length > 0) {
                  if (tempDocArray[0].file !== null) {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].file',
                        tempDocArray[0].file
                    );
                  }
                  if (tempDocArray[0].fileName === undefined) {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].fileName',
                        ''
                    );
                  } else {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].fileName',
                        tempDocArray[0].fileName
                    );
                  }
                  documentFormData.append(
                      'gmDocumentVO[' +
                      key +
                      '].meetingDocAttachList[' +
                      docKey +
                      '].langCode',
                      'EN'
                  );

                  if (tempDocArray[0].logicalFileName === undefined) {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].logicalFileName',
                        ''
                    );
                  } else {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].logicalFileName',
                        tempDocArray[0].logicalFileName
                    );
                  }
                  docKey++;
                }
              }
              if (selectedDutch) {
                tempDocArray = $filter('filter')(value.meetingDocAttachList, {
                  langCode: 'NL',
                });
                if (tempDocArray && tempDocArray.length > 0) {
                  if (tempDocArray[0].file !== null) {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].file',
                        tempDocArray[0].file
                    );
                  }
                  if (tempDocArray[0].fileName === undefined) {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].fileName',
                        ''
                    );
                  } else {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].fileName',
                        tempDocArray[0].fileName
                    );
                  }
                  documentFormData.append(
                      'gmDocumentVO[' +
                      key +
                      '].meetingDocAttachList[' +
                      docKey +
                      '].langCode',
                      'NL'
                  );

                  if (tempDocArray[0].logicalFileName === undefined) {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].logicalFileName',
                        ''
                    );
                  } else {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].logicalFileName',
                        tempDocArray[0].logicalFileName
                    );
                  }
                  docKey++;
                }
              }
              if (selectedFrench) {
                tempDocArray = $filter('filter')(value.meetingDocAttachList, {
                  langCode: 'FR',
                });
                if (tempDocArray && tempDocArray.length > 0) {
                  if (tempDocArray[0].file !== null) {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].file',
                        tempDocArray[0].file
                    );
                  }
                  if (tempDocArray[0].fileName === undefined) {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].fileName',
                        ''
                    );
                  } else {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].fileName',
                        tempDocArray[0].fileName
                    );
                  }
                  documentFormData.append(
                      'gmDocumentVO[' +
                      key +
                      '].meetingDocAttachList[' +
                      docKey +
                      '].langCode',
                      'FR'
                  );

                  if (tempDocArray[0].logicalFileName === undefined) {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].logicalFileName',
                        ''
                    );
                  } else {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].logicalFileName',
                        tempDocArray[0].logicalFileName
                    );
                  }
                  docKey++;
                }
              }
              if (selectedGerman) {
                tempDocArray = $filter('filter')(value.meetingDocAttachList, {
                  langCode: 'DE',
                });
                if (tempDocArray && tempDocArray.length > 0) {
                  if (tempDocArray[0].file !== null) {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].file',
                        tempDocArray[0].file
                    );
                  }
                  if (tempDocArray[0].fileName === undefined) {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].fileName',
                        ''
                    );
                  } else {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].fileName',
                        tempDocArray[0].fileName
                    );
                  }
                  documentFormData.append(
                      'gmDocumentVO[' +
                      key +
                      '].meetingDocAttachList[' +
                      docKey +
                      '].langCode',
                      'DE'
                  );

                  if (tempDocArray[0].logicalFileName === undefined) {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].logicalFileName',
                        ''
                    );
                  } else {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].logicalFileName',
                        tempDocArray[0].logicalFileName
                    );
                  }
                  docKey++;
                }
              }
              if (selectedSpanish) {
                tempDocArray = $filter('filter')(value.meetingDocAttachList, {
                  langCode: 'ES',
                });
                if (tempDocArray && tempDocArray.length > 0) {
                  if (tempDocArray[0].file !== null) {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].file',
                        tempDocArray[0].file
                    );
                  }
                  if (tempDocArray[0].fileName === undefined) {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].fileName',
                        ''
                    );
                  } else {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].fileName',
                        tempDocArray[0].fileName
                    );
                  }
                  documentFormData.append(
                      'gmDocumentVO[' +
                      key +
                      '].meetingDocAttachList[' +
                      docKey +
                      '].langCode',
                      'ES'
                  );

                  if (tempDocArray[0].logicalFileName === undefined) {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].logicalFileName',
                        ''
                    );
                  } else {
                    documentFormData.append(
                        'gmDocumentVO[' +
                        key +
                        '].meetingDocAttachList[' +
                        docKey +
                        '].logicalFileName',
                        tempDocArray[0].logicalFileName
                    );
                  }
                  docKey++;
                }
              }
            });
          }
          return documentFormData;
        }
      };
    },
  ]);
})();

// Service function for creating general meeting document data
