/**
 * @ngdoc factory
 * @name commonModule.MyAccountsService
 * @description This is a factory used for shareholder module related REST calls
 * @requires $http for POST request
 * @requires $resource for the REST calls
 * @requires $sessionStorage to store/pass session data
 * @requires $log for debug logs
 */
(function() {
  'use strict';
  angular.module('commonModule').factory(
          'MyAccountsService',
          [
              '$http',
              '$resource',
              '$log',
              function($http, $resource,$log) {
                return{
                  getProfileData: function(userId, userType){
                    return $http({
                     headers : {
                       'Accept':'application/json',
                              'Content-Type':'application/json'
                     },
                     url : 'v1/users/myaccount/'+userId+'/'+userType+'/profile',
                     method : 'GET'
                    });
                  },
                  updateProfileData: function(userId,userType,data,authtoken){
                    $log.debug(data);
                    return $http({
                       headers : {
                         'Accept':'application/json',
                                'Content-Type':'application/json',
                                'X-Internal-Auth-Token': authtoken
                       },
                       url : 'v1/users/myaccount/'+userId+'/'+userType+'/profile',
                       method : 'PUT',
                       data : data
                      });
                  },
                  updatePassword: function(userId,data,authtoken){
                    $log.debug(data);
                    return $http({
                       headers : {
                         'Accept':'application/json',
                                'Content-Type':'application/json',
                                'X-Internal-Auth-Token': authtoken
                       },
                       url : 'v1/users/'+userId+'/password',
                       method : 'PUT',
                       data : data
                      });
                  },
                  updateExpiredPassword: function(data,authtoken){
                      return $http({
                         headers : {
                           'Accept':'application/json',
                                  'Content-Type':'application/json',
                                  'X-Internal-Auth-Token': authtoken
                         },
                         url : 'v1/users/password',
                         method : 'PUT',
                         data : data
                        });
                    },
                  getIssuerGroupDetails: function(issuerId,languageCode) {
                    return $http({
                      headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                      },
                      url: 'v1/issuers/groups/'+issuerId+'/GM/'+languageCode,
                      method: 'GET'
                    });
                  },
                  getIntermediaryData: function(intermediaryId) {
                    return $resource('v1/profiles/'
                            + intermediaryId, {}, {
                      query: {
                        method: 'GET',
                        isArray: false
                      }
                    });
                  },
                  getIntermediaries: function (productCode, proxyCode) {
                    return $http({
                      headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                      },
                      url: '/v2/public/intermediaries?product-code='
                          + productCode + '&proxy-code=' + proxyCode,
                      method: 'GET'
                    });
                  }
                };
              }]);
             })();
