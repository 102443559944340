/**
 * @ngdoc service
 * @name commonModule.UserNavigationHistory
 * @description This is a service used for dealing with page navigation history
 * @requires $filter
 */
angular.module('commonModule').service('UserNavigationHistory', [
  '$filter',
  function ($filter) {
    this.historyStack = [];
    this.previousItem = {};
    this.historyItems = {};

    this.push = function (
      toState,
      toStateParam,
      fromState,
      fromStateParams,
      nameList
    ) {
      var history = {};
      history['toState'] = toState;
      history['toStateParam'] = toStateParam;
      history['fromState'] = fromState;
      history['fromStateParams'] = fromStateParams;

      if (nameList?.length > 0) {
        this.historyItems[nameList[nameList.length - 1]] = history;
      }
      if (fromState != null && !fromState.url) return;

      if (!angular.equals(history.toState, this.previousItem?.fromState)) {
        this.historyStack.push(history);
      }
    };

    this.getPreviousState = function () {
      var item = this.historyStack.pop();
      this.previousItem = item;
      return item;
    };

    this.getCurrentPageName = function () {
      var pageName = 'Home';
      if (this.historyStack.length > 0) {
        var state = this.historyStack[this.historyStack.length - 1];
        if (state?.toState?.data?.nameList?.length > 0)
          pageName =
            state.toState.data.nameList[state.toState.data.nameList.length - 1];
        pageName = $filter('translate')(pageName);
      }
      return pageName;
    };

    this.getStateByName = function (item) {
      var state = this.historyItems[item];
      if (state != null && state.toState != null && state.toState.url) {
        return state;
      } else {
        // iterate through history items and find out next history
        var found = false;
        for (var propertyName in this.historyItems) {
          if (found) {
            state = this.historyItems[propertyName];
            break;
          }
          if (propertyName === item) {
            found = true;
          }
        }
        return state;
      }
    };

    // Functions defined to handle Page refresh
    this.getHistoryStack = function () {
      return this.historyStack;
    };

    this.setHistoryStack = function (historyStack) {
      this.historyStack = historyStack;
    };

    this.getHistoryItems = function () {
      return this.historyItems;
    };

    this.setHistoryItems = function (historyItems) {
      this.historyItems = historyItems;
    };
  },
]);
