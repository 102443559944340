import { MenuItem, MEETING_LABELS, ACCOUNT_LABELS } from './_common';

export function getShareholderMenuItems(lang = 'EN') {
  const labels = {
    events: {
      EN: 'My events',
      NL: 'Mijn events',
      FR: 'Mes événements',
      DE: 'Meine Veranstaltungen',
      ES: 'Mis eventos'
    },
    meetings: MEETING_LABELS,
    account: ACCOUNT_LABELS,
  };

  return [
    new MenuItem(labels.events[lang], 'shMyVirtualParticipation', 0),
    new MenuItem(labels.meetings[lang], 'shGeneralMeetings', 1),
    new MenuItem(labels.account[lang], 'myAccounts', 2),
  ];
}
