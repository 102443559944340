/**
 * @ngdoc service
 * @name commonModule.feedbackService
 * @description This is a service used for feed back functionality
 * @requires UserNavigationHistory for history of page navigation
 * @requires $http for the REST calls
 */
angular.module('commonModule').service(
        'feedbackService',
        [
            'UserNavigationHistory',
            '$http',
            function(UserNavigationHistory, $http) {

              this.createFeedback = function($sessionStorage, feedbackType,
                      comment) {

                var feedbackObject = {};
                if ($sessionStorage) {
                  feedbackObject['feedbackType'] = feedbackType;
                  feedbackObject['userName'] = $sessionStorage.userName;
                  feedbackObject['email'] = $sessionStorage.emailAddress;
                  feedbackObject['userId'] = $sessionStorage.userId;
                  feedbackObject['screen'] = UserNavigationHistory
                          .getCurrentPageName();
                  feedbackObject['workstation'] = $sessionStorage.workstation;
                  feedbackObject['userComments'] = comment;

                }
                return feedbackObject;
              };

              this.saveFeedback = function(feedbackObject) {
                return $http({
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  url: 'v1/feedback',
                  method: 'POST',
                  data: feedbackObject
                });

              };
            }]);
