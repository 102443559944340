/**
 * @ngdoc factory
 * @name userMaintenanceModule.userGroupService
 * @description This is a factory used for user maintenance related REST calls
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $resource for REST calls.
 */

(function () {
  'use strict';

  angular.module('userMaintenanceModule').factory('userGroupService', [
    '$resource',
    '$log',
    '$http',
    function ($resource, $log, $http) {
      return {
        getAgentGroup: function (agentId, productCode, languageCode) {
          return $resource(
            'v1/agents/' +
              agentId +
              '/group/' +
              productCode +
              '/' +
              languageCode,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getContactDetails: function (userType) {
          return $resource(
            'v1/agents/contactdetail/' + userType,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getCountryList: function (languageCode) {
          return $resource(
            '/v2/common/open/countries',
            {
              params: {
                languageCode: languageCode,
              },
            },
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getIntermediaryList: function (productCode) {
          return $resource(
            'v1/peshareholders/' + productCode,
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        editIssuerGroup: function (data, authtoken, callback) {
          $log.debug(data);
          var response = $resource(
            'v1/issuers',
            {},
            {
              save: {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'X-Internal-Auth-Token': authtoken,
                },
              },
            }
          );
          var _resource = new response(data);
          _resource.$save(callback);
        },
        editIntermediaryGroup: function (groupId, data, authtoken, callback) {
          $log.debug(data);
          var response = $resource(
            'v1/intermediaries/' + groupId,
            {},
            {
              save: {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'X-Internal-Auth-Token': authtoken,
                },
              },
            }
          );
          var _resource = new response(data);
          _resource.$save(callback);
        },
        editVCGroup: function (vcId, data) {
          $log.debug(data);

          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/votecollectors/' + vcId,
            method: 'PUT',
            data: data,
          });
        },
        getIssuerGroup: function (productCode, languageCode) {
          return $resource(
            'v1/issuers/' + productCode + '/' + languageCode,
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getVCGroup: function (productCode, languageCode) {
          return $resource(
            'v1/votecollectors/' + productCode + '/' + languageCode,
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getIntermediaryGroup: function (productCode, languageCode) {
          return $resource(
            'v1/intermediaries/' + productCode + '/' + languageCode,
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getProxyGroup: function (productCode, languageCode) {
          return $resource(
            'v1/intermediaries/' +
              productCode +
              '/' +
              languageCode +
              '/proxies',
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getViewIssuerGroup: function (issuerId, productCode, languageCode) {
          return $resource(
            'v1/issuers/groups/' +
              issuerId +
              '/' +
              productCode +
              '/' +
              languageCode,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getViewIntermediaryGroup: function (
          intermediaryId,
          productCode,
          languageCode
        ) {
          return $resource(
            'v1/intermediaries/groups/' +
              intermediaryId +
              '/' +
              productCode +
              '/' +
              languageCode,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getViewVCGroup: function (vcId, productCode, languageCode) {
          return $resource(
            'v1/votecollectors/groups/' +
              vcId +
              '/' +
              productCode +
              '/' +
              languageCode,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        createVCGroup: function (data) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/votecollectors',
            method: 'POST',
            data: data,
          });
        },
        deleteIssuerGroup: function (
          groupId,
          userType,
          productCode,
          authtoken,
          callback
        ) {
          var response = $resource(
            'v1/issuers/' + groupId + '/' + userType + '/' + productCode,
            {},
            {
              save: {
                method: 'PUT',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'X-Internal-Auth-Token': authtoken,
                },
              },
            }
          );
          var _resource = new response();
          _resource.$save(callback);
        },
        enableIssuerGroup: function (groupId, productCode, callback) {
          let response = $resource(
            'v1/issuers/' + groupId + '/' + productCode + '/enable',
            {},
            {
              save: {
                method: 'PUT',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              },
            }
          );
          let _resource = new response();
          _resource.$save(callback);
        },
        disableIssuerGroup: function (groupId, productCode, callback) {
          let response = $resource(
            'v1/issuers/' + groupId + '/' + productCode + '/disable',
            {},
            {
              save: {
                method: 'PUT',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              },
            }
          );
          let _resource = new response();
          _resource.$save(callback);
        },
        enableIntermediaryGroup: function (groupId, productCode, callback) {
          let response = $resource(
            'v1/intermediaries/' + groupId + '/' + productCode + '/enable',
            {},
            {
              save: {
                method: 'PUT',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              },
            }
          );
          let _resource = new response();
          _resource.$save(callback);
        },
        disableIntermediaryGroup: function (groupId, productCode, callback) {
          let response = $resource(
            'v1/intermediaries/' + groupId + '/' + productCode + '/disable',
            {},
            {
              save: {
                method: 'PUT',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              },
            }
          );
          let _resource = new response();
          _resource.$save(callback);
        },
        enableVoteCollectorGroup: function (groupId, callback) {
          let response = $resource(
            'v1/votecollectors/' + groupId + '/enable',
            {},
            {
              save: {
                method: 'PUT',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              },
            }
          );
          let _resource = new response();
          _resource.$save(callback);
        },
        disableVoteCollectorGroup: function (groupId, callback) {
          let response = $resource(
            'v1/votecollectors/' + groupId + '/disable',
            {},
            {
              save: {
                method: 'PUT',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              },
            }
          );
          let _resource = new response();
          _resource.$save(callback);
        },
        getPermissions: function (groupId, userId, productCode) {
          return $resource(
            'v1/intermediaries/' +
              groupId +
              '/' +
              userId +
              '/' +
              productCode +
              '/agreementforms',
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getPermissionsIssuer: function (groupId, userId, productCode) {
          return $resource(
            'v1/issuers/' +
              groupId +
              '/' +
              userId +
              '/' +
              productCode +
              '/agreementforms',
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getLinkedIntermediaries: function (groupId) {
          return $resource(
            'v1/intermediaries/' + groupId + '/link',
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        // SFCBP-3025 changes
        getIntermediaryGroupList: function (prodCode, intermediaryName) {
          return $resource(
            'v1/intermediaries/listOfIntermediaries?prodCode=' +
              prodCode +
              '&intermediaryName=' +
              intermediaryName,
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        delinkProxyAndIntermediary: function (
          intermediaryId,
          proxyId,
          authtoken,
          callback
        ) {
          var response = $resource(
            'v1/intermediaries/' + intermediaryId + '/' + proxyId + '/delink',
            {},
            {
              save: {
                method: 'DELETE',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'X-Internal-Auth-Token': authtoken,
                },
              },
            }
          );
          var _resource = new response();
          _resource.$save(callback);
        },
        getIssuerGroupLogo: function (issuerId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/issuers/logo/' + issuerId,
            method: 'GET',
          });
        },
        // SFCBP-2666 - Start
        getNonProxiesList: function (prodCode, intermediaryName) {
          return $resource(
            'v1/intermediaries/listOfIntermediaries?prodCode=' +
              prodCode +
              '&intermediaryName=' +
              intermediaryName,
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        // SFCBP-2666 - End
        getAllNonProxiesList: function () {
          return $resource(
            'v1/intermediaries/listOfAllIntermediaries',
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
      };
    },
  ]);
})();
