/**
 * @ngdoc service
 * @name commonModule.touService
 * @description This is a service used for dealing with Terms of Use
 */
angular.module('commonModule').service('touService', [
  function () {
    return {
      getTouUrl: function (productType, language) {
        return window.location.origin
            + '/v1/static-content/html/ToU-'
            + productType + '-'
            + language
            + ".html";
      }
    }
  }]);
