(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name : Tab data change service
   * @returns : error response map from service call, for send to Issuer flow.
   * @description : Service to generate list of errors for different section in
   *              create GM response.
   */

  angular.module('commonModule').factory('tabDataChangeService', [
    '$http',
    '$log',
    '$filter',
    function ($http, $log, $filter) {
      return {
        // Service function to check equality of masterData and currentData
        isTabDataEqual: function (masterData, currentData) {
          $log.debug(currentData);
          return angular.equals(masterData, currentData);
        },
        // Service function to get send To Issuers Field Errors
        sendToIssuersFieldErrors: function (error) {
          var errorSections = [];
          var sendToApproverErrors = [];
          if (error.errors.length > 0) {
            for (var i = 0; i < error.errors.length; i++) {
              errorSections.push(error.errors[i].errorCode);
            }
          }
          // capturing field level errors
          if (error.gmScheduleVO.errors.length > 0) {
            for (var j = 0; j < error.gmScheduleVO.errors.length; j++) {
              sendToApproverErrors.push(
                error.gmScheduleVO.errors[j].errorMessage
              );
            }
          }

          if (
            error.gmAgendaVO !== undefined &&
            error.gmAgendaVO !== null &&
            error.gmAgendaVO.length > 0
          ) {
            for (var k = 0; k < error.gmAgendaVO.length; k++) {
              if (error.gmAgendaVO[k].errors.length > 0) {
                for (var l = 0; l < error.gmAgendaVO[k].errors.length; l++) {
                  sendToApproverErrors.push(
                    error.gmAgendaVO[k].errors[l].errorMessage
                  );
                }
              }
            }
          } else {
            sendToApproverErrors.push(
              $filter('translate')('label_agendaRequired')
            );
          }

          if (
            error.gmDocumentVO !== undefined &&
            error.gmDocumentVO !== null &&
            error.gmDocumentVO.length > 0
          ) {
            for (var m = 0; m < error.gmDocumentVO.length; m++) {
              if (error.gmDocumentVO[m].errors.length > 0) {
                for (var n = 0; n < error.gmDocumentVO[m].errors.length; n++) {
                  sendToApproverErrors.push(
                    error.gmDocumentVO[m].errors[n].errorMessage
                  );
                }
              }
            }
          } else {
            sendToApproverErrors.push(
              $filter('translate')('label_docRequired')
            );
          }

          if (
            error.gmShareClassVO !== undefined &&
            error.gmShareClassVO !== null &&
            error.gmShareClassVO.length > 0
          ) {
            for (var o = 0; o < error.gmShareClassVO.length; o++) {
              if (error.gmShareClassVO[o].errors.length > 0) {
                for (
                  var p = 0;
                  p < error.gmShareClassVO[o].errors.length;
                  p++
                ) {
                  sendToApproverErrors.push(
                    error.gmShareClassVO[o].errors[p].errorMessage
                  );
                }
              }
            }
          } else {
            sendToApproverErrors.push(
              $filter('translate')('label_shareClassRequired')
            );
          }
          return [sendToApproverErrors, errorSections];
        },
        // Service function to get send To Issuers language Errors
        sendToIssuerLangErrors: function (errorObjectList) {
          var multilanguageError = [];
          var map = {};
          var sectionMap = {};
          if (errorObjectList !== undefined && errorObjectList !== null) {
            if (
              errorObjectList.errors !== undefined &&
              errorObjectList.errors !== null &&
              errorObjectList.errors.length > 0
            ) {
              angular.forEach(errorObjectList.errors, function (error) {
                if (error.errorType === null) {
                  var sectionObj = {
                    tabName: error.errorCode,
                    lang: [],
                    errorMessage: error.errorMessage,
                  };
                  multilanguageError.push(sectionObj);
                  sectionMap[error.errorCode] = sectionObj;
                } else {
                  if (map['type']) {
                    var array = $filter('filter')(
                      map['type'].lang,
                      error.errorType
                    );
                    if (array.length === 0) {
                      map['type'].lang.push(error.errorType);
                    }
                  } else {
                    var obj = {
                      tabName: 'typeAndLocation',
                      lang: [],
                      errorMessage: 'Error(s) in Type and Location.',
                    };
                    obj.lang.push(error.errorType);
                    multilanguageError.push(obj);
                    map['type'] = obj;
                  }
                }
              });
              // re iterating for detecting languages.
            }
            // Section wise processing
            angular.forEach(sectionMap, function (value, key) {
              // Process Documents
              if (key === 'documents') {
                angular.forEach(errorObjectList.gmDocumentVO, function (
                  document
                ) {
                  angular.forEach(document.errors, function (error) {
                    if (error.errorCode !== 'attachFileToCheckboxes') {
                      value.lang.push(
                        error.errorType === null ? 'EN' : error.errorType
                      );
                    }
                  });
                });
              }
              // Process share classes
              else if (key === 'shareClass') {
                angular.forEach(errorObjectList.gmShareClassVO, function (
                  document
                ) {
                  angular.forEach(document.errors, function (error) {
                    value.lang.push(
                      error.errorType === null ? 'EN' : error.errorType
                    );
                  });
                });
              }
              // Process agenda
              else if (key === 'agenda') {
                angular.forEach(errorObjectList.gmAgendaVO, function (
                  document
                ) {
                  angular.forEach(document.errors, function (error) {
                    value.lang.push(
                      error.errorType === null ? 'EN' : error.errorType
                    );
                  });
                });
              }
            });
          }
          if (multilanguageError.length > 0) {
            angular.forEach(multilanguageError, function (multilang, key) {
              if (multilang.lang.length > 0) {
                var uniqueValuesArrays = multilang.lang.filter(function (
                  element,
                  index,
                  self
                ) {
                  return index === self.indexOf(element);
                });
                multilanguageError[key].lang = uniqueValuesArrays;
              }
            });
          }
          return multilanguageError;
        },
      };
    },
  ]);
})();
