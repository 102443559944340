(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name : dwt Notification service
   * @returns : rest calls to notification detail, creating JSON request objects for service calls.
   * @description : Service to view detail.
   */
  angular.module('dwtNotificationModule').factory('DWTNotificationService', [
    '$http',
    '$resource',
    function ($http) {
      return {
        getNotificationPreview: function (dwtEventId, strParam) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/dwtevents/' +
              dwtEventId +
              '/notification/preview?msgOne=' +
              strParam,
            method: 'GET',
          });
        },
        getNotificationData: function (dwtEventId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/dwtevents/' + dwtEventId + '/notification/data/EN',
            method: 'GET',
          });
        },
        dwtNotificationSend: function (dwtEventId, data) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/dwtevents/' + dwtEventId + '/notification',
            method: 'POST',
            data: data,
          });
        },
        dwtNotificationSave: function (dwtEventId, data) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/dwtevents/' +
              dwtEventId +
              '/notification/addtionalinformation',
            method: 'POST',
            data: data,
          });
        },
      };
    },
  ]);
})();
