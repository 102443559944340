/**
 * @ngdoc factory
 * @name commonModule.virtualParticipationService
 * @description This is a factory used for virtual participation related REST calls
 * @requires $resource for the REST calls
 */
(function () {
  'use strict';
  angular.module('commonModule').factory(
      'virtualParticipationService',
      [
        '$resource',
        function ($resource) {
          return {
            getVirtualParticipationProviders: function (userType) {
              let workstation;
              switch (userType) {
                case 'IS': workstation = 'issuer'
                  break;
                case 'AG': workstation = 'agent'
                  break;
                default:
                  throw new Error('userType not supported');
              }

              return $resource(`v2/gm/${workstation}/virtual-participation/providers`);
            }
          }
        }])
})();