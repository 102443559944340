import {
  getAgentMenuItems,
  getIntermediaryMenuItems,
  getIssuerMenuItems,
  getPublicMenuItems,
  getShareholderMenuItems,
  getShareplanMenuItems,
  getVoteCollectorMenuItems,
} from './menus';

/**
 * @ngdoc service
 * @name MenuModule.menuService
 * @description This is a service used for menu related controls
 * @requires menuService there are no service calls. But this is used for controlling the menu items for
 * all the workstations as part of header.
 */
angular.module('MenuModule', []).service('menuService', function () {
  /**
   * @typedef {object} LogoutObject
   * @property {string} url - the URL (i.e. state) to redirect to when logging out, I guess?
   *
   * @typedef {object} MenuItem
   * @property {string} name - the label to appear in the header menu
   * @property {string} url - the *state name* to redirect to when clicked
   * @property {number} index - the index in the header menu, I guess?
   *
   * @typedef {object} MenuSomething
   * @property {MenuItem[]} menus - array of menuItems to display in some navigational element
   * @property {LogoutObject} logOut
   *
   * @return {MenuSomething} or w/e????
   */
  this.getMenuItemsByWorkStationId = function (
    workStationId,
    lang,
    gmId,
    issuerId,
    productTypes,
    isAdmin,
    userGroupProductCodes
  ) {
    if (productTypes === null || productTypes === undefined) {
      productTypes = ['GM'];
    }

    if (!lang) {
      lang = 'EN';
    }

    const logoutStates = {
      AG: 'agent',
      IN: 'intermediary',
      IS: 'issuer',
      SH: 'shareHolderLogout',
      SP: 'shareplan',
      VC: 'votecollector',
    };

    const menuMap = {
      AG: getAgentMenuItems,
      IN: getIntermediaryMenuItems,
      IS: getIssuerMenuItems,
      SH: getShareholderMenuItems,
      SP: getShareplanMenuItems,
      VC: getVoteCollectorMenuItems,
    };

    if (!(workStationId in menuMap)) {
      return {
        menus: getPublicMenuItems(lang),
      };
    }

    /** Additonal options affecting the returned menuItems. Used by some userTypes, but most don't need it */
    const opts = {
      gmId,
      issuerId,
      isAdmin,
      userGroupProductCodes: [],
    };

    return {
      menus: menuMap[workStationId](lang, productTypes, opts),
      logOut: {
        url: logoutStates[workStationId],
      },
    };
  };

  // to find and return selected index Id of Tax reclaim main menu.
  this.findSelectedIndexId = function (productTypes, isAdmin) {
    var taxReclaimIndexId;
    if (
      productTypes.length > 0 &&
      productTypes.indexOf('GM') !== -1 &&
      productTypes.indexOf('DWT') !== -1
    ) {
      taxReclaimIndexId = 2;
    } else if (
      productTypes.length > 0 &&
      productTypes.indexOf('DWT') !== -1 &&
      isAdmin !== 'R'
    ) {
      taxReclaimIndexId = 1;
    } else if (
      productTypes.length > 0 &&
      productTypes.indexOf('DWT') !== -1 &&
      isAdmin === 'R'
    ) {
      taxReclaimIndexId = 0;
    } else {
      taxReclaimIndexId = 0;
    }
    return taxReclaimIndexId;
  };

  // to find if user is a tax authority type user.
  this.findTaxAuthorityUser = function (productTypes, isAdmin) {
    var dwtTaxAuthTypeUser = false;
    if (
      productTypes.length > 0 &&
      productTypes.indexOf('DWT') !== -1 &&
      isAdmin === 'R'
    ) {
      dwtTaxAuthTypeUser = true;
    }
    return dwtTaxAuthTypeUser;
  };
});
