/**
 * @ngdoc factory
 * @name actionHomeModule.issuerGmActionService
 * @description This is a factory used for issuer work station related REST calls
 * @requires $http for POST request
 * @requires $resource for the REST calls
 * @requires $sessionStorage to store/pass session data
 * @requires $log to handle debug logs
 */
(function () {
  'use strict';

  angular.module('actionHomeModule').factory('issuerGmActionService', [
    '$http',
    '$sessionStorage',
    '$resource',
    '$log',
    function ($http, $sessionStorage, $resource) {
      return {
        getIssuerGeneralMeetingsActions: function (pageNumber, pageSize) {
          return $resource(
            'v2/gm/issuer/general-meetings/actions?pageNumber=' +
              pageNumber +
              '&pageSize=' +
              pageSize,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
      };
    },
  ]);
})();
