(function () {
  'use strict';
  /**
   * @ngdoc dwtQrfBulkUploadModule
   * @name : view dwt detail service
   * @returns : rest calls to view dwt detail, creating JSON request objects for service calls.
   * @description : Service to view dwt detail.
   */
  angular.module('dwtQrfBulkUploadModule').factory('QrfBulkUploadService', [
    '$http',
    '$localStorage',
    '$sessionStorage',
    '$resource',
    function ($http, $localStorage, $sessionStorage, $resource) {
      return {
        uploadQRFBulkUpload: function (fd) {
          return $http
            .post('v1/bulkupload/upload', fd, {
              transformRequest: angular.identity,
              headers: {
                'Content-Type': undefined,
              },
            })
            .then(
              function (success) {
                return success;
              },
              function (error) {
                return error;
              }
            );
        },
        saveBulkUploadQrf: function (data, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/bulkupload',
            method: 'POST',
            data: data,
          });
        },
        createDwtExtensionRequest: function (data) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/dwt/extension-requests',
            method: 'POST',
            data: data,
          });
        },
        updateDwtExtensionRequest: function (extensionRequestId, data) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/dwt/extension-requests/' + extensionRequestId,
            method: 'PUT',
            data: data,
          });
        },
        getBulkQrfLandingPageData: function (intermediaryId, eventId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/bulkupload/' + intermediaryId + '/' + eventId + '/home',
            method: 'GET',
          });
        },
      };
    },
  ]);
})();
