/**
 * @ngdoc factory
 * @name userMaintenanceModule.userService
 * @description This is a factory used for user maintenance related REST calls
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $http for POST request
 * @requires $resource for other REST calls.
 */

(function() {
  'use strict';

  angular
          .module('userMaintenanceModule')
          .factory(
                  'userService',
                  [
                      '$resource',
                      '$log',
                      '$http',
                      function($resource, $log, $http) {
                        return {
                          getUserList: function(groupId, userType, productCode) {
                            return $resource('v1/users/viewusers/' + groupId + '/'
                                    + userType + '/' + productCode, {}, {
                              query: {
                                method: 'GET',
                                isArray: true
                              }
                            });
                          },
                          users: function() {
                            return $resource('/v2/agent/users', {}, {
                              query: {
                                method: 'GET',
                                isArray: false
                              }
                            });
                          },
                          getActionUserList: function(gmId) {
                            return $resource(
                                    'v1/votecollectors/generalMeeting/' + gmId
                                            + '/EN', {}, {
                                      query: {
                                        method: 'GET',
                                        isArray: true
                                      }
                                    });
                          },
                          createNewUser: function(data, authtoken) {
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'X-Internal-Auth-Token': authtoken
                              },
                              url: 'v1/users/create',
                              method: 'POST',
                              data: data
                            });
                          },
                          getViewUser: function(userId, userType, groupId,
                                  productCode) {
                            return $resource('v1/users/' + userId + '/'
                                    + userType + '/' + groupId + '/'
                                    + productCode, {}, {
                              query: {
                                method: 'GET',
                                isArray: false
                              }
                            });
                          },
                          editUser: function(userId, data, authtoken, callback,
                                  errorCallback) {
                            $log.info(data);
                            $log.debug(userId);
                            var response = $resource('v1/users/edit/' + userId, {},
                                    {
                                      'save': {
                                        method: 'PUT',
                                        headers: {
                                          'Accept': 'application/json',
                                          'Content-Type': 'application/json',
                                          'X-Internal-Auth-Token': authtoken
                                        },
                                        data: data
                                      }
                                    });
                            var _resource = new response(data);
                            _resource.$save(callback, errorCallback);
                          },
                          deleteUser: function(userId, productCode, authtoken,
                                  callback) {
                            var response = $resource('v1/users/' + userId + '/'
                                    + productCode, {}, {
                              'save': {
                                method: 'PUT',
                                headers: {
                                  'Accept': 'application/json',
                                  'Content-Type': 'application/json',
                                  'X-Internal-Auth-Token': authtoken
                                }
                              }
                            });
                            var _resource = new response();
                            _resource.$save(callback);

                          },
                          disableUser: function(userId, productCode, userType,
                                  groupId, loggedInUserId, authtoken, callback) {
                            var response = $resource('v1/users/' + userId + '/'
                                    + productCode + '/' + userType + '/'
                                    + groupId + '/' + loggedInUserId, {}, {
                              'save': {
                                method: 'PUT',
                                headers: {
                                  'Accept': 'application/json',
                                  'Content-Type': 'application/json',
                                  'X-Internal-Auth-Token': authtoken
                                }
                              }
                            });
                            var _resource = new response();
                            _resource.$save(callback);

                          },
                          getShareclassList: function(issuerId, prodCode,
                                  langCode, userId) {
                            return $resource('v1/dwtshareclass/' + issuerId
                                    + '/' + prodCode + '/' + langCode + '/'
                                    + userId, {}, {
                              query: {
                                method: 'GET',
                                isArray: true
                              }
                            });
                          },
                          createNewShareclass: function(data, authtoken) {
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'X-Internal-Auth-Token': authtoken
                              },
                              url: 'v1/dwtshareclass',
                              method: 'POST',
                              data: data
                            });
                          },
                          deleteShareclass: function(shareclassId, productCode,
                                  languageCode, userId, authtoken) {
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'X-Internal-Auth-Token': authtoken
                              },
                              url: 'v1/dwtshareclass' + '/' + shareclassId
                                      + '/' + productCode + '/' + languageCode
                                      + '/' + userId,
                              method: 'DELETE'
                            });
                          },
                          editShareclass: function(shareClassId, data,
                                  authtoken, callback, errorCallback) {
                            var response = $resource('v1/dwtshareclass/'
                                    + shareClassId, {}, {
                              'save': {
                                method: 'PUT',
                                headers: {
                                  'Accept': 'application/json',
                                  'Content-Type': 'application/json',
                                  'X-Internal-Auth-Token': authtoken
                                },
                                data: data
                              }
                            });
                            var _resource = new response(data);
                            _resource.$save(callback, errorCallback);
                          },
                          getShareclass: function(shareClassId, groupId,
                                  prodCode, langCode, userId) {
                            return $resource('v1/dwtshareclass/' + groupId
                                    + '/' + shareClassId + '/' + prodCode + '/'
                                    + langCode + '/' + userId, {}, {
                              query: {
                                method: 'GET',
                                isArray: false
                              }
                            });
                          },
                          getDefaultIsin: function(groupId, shareclassId,
                                  prodCode, langCode, userId) {
                            return $resource('v1/peshareholders/' + groupId
                                    + '/' + shareclassId + '/' + prodCode + '/'
                                    + langCode + '/' + userId + '/shareclass',
                                    {}, {
                                      query: {
                                        method: 'GET',
                                        isArray: true
                                      }
                                    });
                          },
                          getShareholderList: function(issuerId, prodCode,
                                  langCode, userId) {
                            return $resource('v1/peshareholders/' + issuerId
                                    + '/' + prodCode + '/' + userId, {}, {
                              query: {
                                method: 'GET'
                              }
                            });
                          },
                          createNewShareholder: function(data) {
                            return $http.post('v1/peshareholders', data, {
                              transformRequest: angular.identity,
                              headers: {
                                'Content-Type': undefined
                              }
                            })
                          },
                          editShareholder: function (shareholderId, data) {
                            return $http.post(
                                'v1/peshareholders/' + shareholderId, data,
                                {
                                  transformRequest: angular.identity,
                                  headers: {
                                    'Content-Type': undefined
                                  }
                                })
                            .then(function (response) {
                              $log.debug(response);
                              return response;
                            }, function (errorResponse) {
                              $log.debug(errorResponse);
                              throw Error(errorResponse);
                            });
                          },
                          deleteShareholder: function(shareholderId,
                                  shareclassId, userId, authtoken) {
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'X-Internal-Auth-Token': authtoken
                              },
                              url: 'v1/peshareholders/' + shareholderId + '/'
                                      + shareclassId + '/' + userId,
                              method: 'DELETE'
                            });
                          },
                          deleteEventShareholder: function(eventId, shareholderId,
                              shareclassId) {
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                              },
                              url: 'v1/peshareholders/' + shareholderId + '/'
                                  + shareclassId + '/events/' + eventId,
                              method: 'DELETE'
                            });
                          },
                          getShareholder: function(groupId, shareholderId,
                                  prodCode, userId) {
                            return $resource('v1/peshareholders/' + groupId
                                    + '/' + shareholderId + '/' + prodCode
                                    + '/' + userId + '/view', {}, {
                              query: {
                                method: 'GET',
                                isArray: false
                              }
                            });
                          },
                          sendResetPwdLink: function(data, authtoken) {
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'X-Internal-Auth-Token': authtoken
                              },
                              url: 'v1/users/resend/activiation/email',
                              method: 'PUT',
                              data: data
                            });
                          },
                          getAllUserList: function(userId, productCode) {
                            return $resource('v1/agents/view/' + userId + '/'
                                    + productCode + '/users', {}, {
                              query: {
                                method: 'GET',
                                isArray: true
                              }
                            });
                          },
                          getIssuerGroupLogo: function(issuerId) {
                            return $http({
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                              },
                              url: 'v1/issuers/logo/' + issuerId ,
                              method: 'GET'
                            });
                          }
                        };
                      }]);

})();
