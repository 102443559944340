/**
 * @ngdoc factory
 * @name shareHolderModule.ParticipationMethodService
 * @description This is a factory used for participation method related REST calls
 * @requires $http for the REST calls
 */
(function () {
  'use strict';
  angular.module('shareHolderModule').factory(
      'participationMethodService',
      [
        '$http',
        function ($http) {
          return {
            getParticipationMethods: function (meetingId, shareclassId) {
              return $http({
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                url: `v2/gm/general-meetings/${meetingId}/shareclasses/${shareclassId}/participation-methods`,
                method: 'GET',
              });
            },
            getVotingParticipationMethods(){
              return  [
                {
                  value: 'EVOT',
                  name: 'Vote by Correspondence',

                }, {
                  value: 'PRXY',
                  name: 'Participation by Proxy',

                }
              ];
            }
          };
        }]);
})();