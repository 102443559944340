/**
 * @ngdoc factory
 * @name shareHolderModule.voteInstructionService
 * @description This is a factory used for shareholder module related REST calls
 * @requires $http for POST request
 * @requires $resource for other REST calls.
 */
(function () {
  'use strict';
  angular.module('shareHolderModule').factory('voteInstructionService', [
    '$http',
    '$resource',
    function ($http, $resource) {
      return {
        getShareClassList: function (
          meetingId,
          shareholderId,
          langCode,
          userType
        ) {
          return $resource(
            'v1/voteinstructions/shareclasses/' +
              meetingId +
              '/' +
              shareholderId +
              '/' +
              langCode +
              '/' +
              userType,
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getShareClassListWithVotes: function (
          meetingId,
          shareholderId,
          langCode,
          userType,
          instructionId,
          status
        ) {
          return $resource(
            'v1/voteinstructions/shareclasses/' +
              meetingId +
              '/' +
              shareholderId +
              '/' +
              langCode +
              '/' +
              userType +
              '/' +
              instructionId +
              '/' +
              status,
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getIntermediaryPOAList: function (groupId, userId) {
          return $resource(
            'v1/generalmeetings/intermediary/' + groupId + '/' + userId,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getVotingInstructionWithVotesAndAgenda: function (instructionId, lang) {
          return $resource(
              'v1/gm/voting-instructions/' +
              instructionId +
              '?langCode=' +
              lang,
              {},
              {
                query: {
                  method: 'GET',
                  isArray: false,
                },
              }
          );
        },
        getAgendaDetails: function (
          meetingId,
          langCode,
          shareholderId,
          shareClassId,
          intermediaryId,
          wsType
        ) {
          return $resource(
            'v1/voteinstructions/agendas/' +
              meetingId +
              '/' +
              langCode +
              '/' +
              shareholderId +
              '/' +
              shareClassId +
              '/' +
              intermediaryId +
              '/' +
              wsType,
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getAgendaDetailsShView: function (
          meetingId,
          langCode,
          shareholderId,
          shareClassId,
          intermediaryId,
          wsType,
          status
        ) {
          return $resource(
            'v1/voteinstructions/view/agendas/' +
              meetingId +
              '/' +
              langCode +
              '/' +
              shareholderId +
              '/' +
              shareClassId +
              '/' +
              intermediaryId +
              '/' +
              wsType +
              '/' +
              status,
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getShareHolderDetailsIntWS: function (
          shareholderId,
          instructionId,
          wsType
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/voteinstructions/shareholder/' +
              shareholderId +
              '/' +
              instructionId +
              '/' +
              wsType,
            method: 'GET',
          });
        },
        getProfileDropDownList: function (langCode, wsType) {
          return $resource(
            'v1/profiles/titles/' + langCode + '/' + wsType,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getLinkedIntermediaries: function (proxyId) {
          return $resource(
            'v1/intermediaries/' + proxyId + '/link',
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getVotingProxyList: function (
          meetingId,
          securityAccNo,
          shareClassId,
          shareholderId,
          userType,
          userId
        ) {
          return $resource(
            'v1/voteinstructions/' +
              meetingId +
              '/' +
              securityAccNo +
              '/' +
              shareClassId +
              '/' +
              shareholderId +
              '/' +
              userId +
              '/' +
              userType,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        saveVotingInstruction: async function (data, userType, saveFuncToCall) {
          // function to call should be createVotingInstruction or updateVotingInstruction
          // based on the action performed by the user
          return saveFuncToCall(data, userType);
        },
        createVotingInstruction: function (data, userType) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: `v1/gm/${userType}/voting-instructions`,
            method: 'POST',
            data: data,
          });
        },
        updateVotingInstruction: function (data, userType) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: `v1/gm/${userType}/voting-instructions`,
            method: 'PUT',
            data: data,
          });
        },
        getIntermediaryVIDetails: function (userId, gmId) {
          return $resource(
            'v1/voteinstructions/gmDetails/' + userId + '/' + gmId,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getRegistrationList: function (
          gmId,
          securityAcctNo,
          shareClassId,
          shareholderId,
          intermediaryId,
          wsType
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/voteinstructions/' +
              gmId +
              '/' +
              securityAcctNo +
              '/' +
              shareClassId +
              '/' +
              shareholderId +
              '/' +
              intermediaryId +
              '/' +
              wsType,
            method: 'GET',
          });
        },
        uploadBOFile: function (fd) {
          return $http
            .post('v1/generalmeetings/intermediary/upload', fd, {
              transformRequest: angular.identity,
              headers: {
                'Content-Type': undefined,
              },
            })
            .then(
              function (success) {
                return success;
              },
              function (error) {
                return error;
              }
            );
        },
        getIntRegistrationDetails: function (
          meetingId,
          intermediaryId,
          langCode,
          issuerName,
          meetingType,
          meetingTime
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/generalmeetings/intermediary/' +
              meetingId +
              '/' +
              intermediaryId +
              '/' +
              langCode +
              '/' +
              issuerName,
            method: 'GET',
          });
        },
        checkVotingRegistrationList: function (
          meetingId,
          shareClassId,
          intermediaryId,
          shareholderName,
          noOfshares,
          reqId
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            data: {
              meetingId: meetingId,
              shareClassId: shareClassId,
              shareholderName: shareholderName,
              noOfshares: noOfshares,
              reqId: reqId,
            },
            url: 'v1/generalmeetings/intermediary/checkShareholderVote',
            method: 'POST',
          });
        },
        checkAttendRegistrationList: function (
          meetingId,
          shareClassId,
          shareholderName,
          noOfshares,
          securityAccountNumber,
          proxyName,
          requestId
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            data: {
              meetingId: meetingId,
              shareClassId: shareClassId,
              shareholderName: shareholderName,
              noOfshares: noOfshares,
              proxyName: proxyName,
              requestId: requestId,
            },
            url: 'v1/generalmeetings/intermediary/checkShareholderAttendance',
            method: 'POST',
          });
        },
        sendSharesExceededMail: function (data, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/agentnotification/noOfSharesExceed',
            method: 'POST',
            data: data,
          });
        },
        getIssuerPolicyText: function (
          meetingId,
          languageCode,
          workstationType
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/generalmeetingdetails/' +
              meetingId +
              '/workstations' +
              '/' +
              workstationType +
              '/policytext' +
              '?preferredLanguageCode=' +
              languageCode,
            method: 'GET',
          });
        },
      };
    },
  ]);
})();
